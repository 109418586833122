import { Controller } from "@hotwired/stimulus"
import L from 'leaflet';
import 'leaflet-defaulticon-compatibility';
import 'leaflet.markercluster';

export default class extends Controller {
  static targets = [ "placeholder" ]

  connect() {
    let centerIcon = L.divIcon({ className: 'map-marker map-marker--center', html: '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-home icon icon--bold"><path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"></path><polyline points="9 22 9 12 15 12 15 22"></polyline></svg>', iconSize: 24 });
    let center = JSON.parse(this.data.get('center'));
    this.map = L.map(this.placeholderTarget, { tap: false }).setView(center, 10);

    L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
    }).addTo(this.map);

    this.addCenterMarker(centerIcon, center);
    this.addMarkers();

    this.map
    .fitBounds(this._getBounds())
    .setCenter;
  }

  disconnect(){
    this.map.remove()
  }

  addCenterMarker(centerIcon, center){
    L.marker(center, { icon: centerIcon }).addTo(this.map);
  }

  addMarkers(){
    let cluster = L.markerClusterGroup();

    this._getMarkers().forEach(marker => {
      let standardIcon = L.divIcon({ className: 'map-marker', html: marker.position, iconSize: 24 });
      let coldIcon = L.divIcon({ className: 'map-marker map-marker--cold', html: marker.position, iconSize: 24 });

      let icon = marker.is_cold ? coldIcon : standardIcon;

      var marker = L.marker(marker.latlng, { icon: icon, position: marker.position, resultId: marker.result_id })
        .bindPopup(marker.popup)
        .on('click', (m) => window.location = `#search_result_${m.target.options.resultId}`)

      cluster.addLayer(marker);
    });

    this.map.addLayer(cluster);
  }

  _getMarkers(){
    return JSON.parse(this.data.get('markers'));
  }

  _getBounds(){
    return this._getMarkers().map(marker => marker.latlng);
  }
}
