import ApplicationController from "./application_controller";
import {get, post} from "@rails/request.js";
import SlimSelect from "slim-select"

// Connects to data-controller="autocomplete"
export default class extends ApplicationController {
  static values = { url: String, extra: String, firstLoadUrl: String, placeholderItem: String, addable: Boolean }
  async connect() {
    const {urlValue, extraValue, addableValue} = this
    const events = {
      ...(urlValue) && {
        search: function (search, currentData) {
          return new Promise(async (resolve, reject) => {
            if (search.length < 1 && search.length > 0) return reject('Search must be at least 1 characters')
            const  { json } = await post(`${urlValue}?keyword=${search}&${extraValue}` )
            let data = await json
            resolve(
              [
                { 'placeholder': true, 'text': this.placeholderItemValue || '', value: '' },
                ...data.map((item) => ({ text: item.name, value: item.id }))
              ]
            )
          })
        }
      },
      ...(addableValue) && { addable: function (value) { return value } }
    }

    const autocompleteSlim = new SlimSelect({
      select: this.element,
      events: events,
      settings: {
        openPosition: 'down',
        allowDeselect: this.element.multiple
      },
    })

    if(this.hasFirstLoadUrlValue) {
      const  { json } = await post(`${this.firstLoadUrlValue}?${extraValue}` )
      let data = await json

      autocompleteSlim.setData(
        [
          { 'placeholder': true, 'text': this.placeholderItemValue || '', value: '' },
          ...data.map((item) => ({ text: item.name, value: item.id }))
        ]
      )
    }
  }
}
