import ApplicationController from "./application_controller";
import SlimSelect from "slim-select";

// Connects to data-controller="slim-select-multiple"
export default class extends ApplicationController {
  connect() {
    let events = {
      ...(this.element.dataset?.slimSelectOptions?.includes('addable')) && (
        { addable: function (value) { return value } }
      )
    }

    new SlimSelect({
      select: this.element,
      events: events
    })
  }
}
