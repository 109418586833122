import * as ActiveStorage from "@rails/activestorage";

ActiveStorage.start();

addEventListener("direct-upload:initialize", event => {
  const { target, detail } = event
  const { id, file } = detail

  const li = document.querySelector(`li[data-file-name="${file.name}"]`)
  li.id = `direct-upload-${id}`;

  const progressContainer = li.querySelector('.direct-upload__progress');
  progressContainer.id = `direct-upload-progress-${id}`;
})

addEventListener("direct-upload:start", event => {
  const { id } = event.detail
  const element = document.getElementById(`direct-upload-${id}`)
  element.classList.remove("direct-upload--pending")
})

addEventListener("direct-upload:progress", event => {
  const { id, progress } = event.detail
  const progressElement = document.getElementById(`direct-upload-progress-${id}`)
  progressElement.style.width = `${progress}%`
})

addEventListener("direct-upload:error", event => {
  event.preventDefault()
  const { id, error } = event.detail
  const element = document.getElementById(`direct-upload-${id}`)
  element.classList.add("direct-upload--error")
  element.setAttribute("title", error)
})

addEventListener("direct-upload:end", event => {
  const { id } = event.detail
  const element = document.getElementById(`direct-upload-${id}`)
  element.classList.add("direct-upload--complete")
})
