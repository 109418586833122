import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="toggle-disabled"
export default class extends Controller {
  static targets = ['input'];

  toggle(event) {
    this.inputTargets.forEach((node) => {
      node.disabled = !node.disabled;
    });
  }
}
