import ApplicationController from "./application_controller";

// Connects to data-controller="provider-selection"
export default class extends ApplicationController {
  static targets = ['selectedProvider', 'provider', 'selectedProviderSearch']
  static values = { storageId: String }

  initialize() {
    localStorage.removeItem(`project-${this.storageIdValue}`);
  }

  connect() {
    this.selectOnConnect()
  }

  selectOnConnect() {
    let storageProvider = localStorage.getItem(`project-${this.storageIdValue}`)?.split(',') || []
    this.providerTargets.forEach((item) => {
      if(storageProvider.includes(item.dataset.providerId) && !this.isSelected(item)) this.buttonToggle(item)
    })
    this.selectedProviderTarget.value = storageProvider.join(',')
  }

  selectDeselectProvider(e) {
    this.buttonToggle(e.target)
    let pageProviders = this.providerTargets
    let pageProvidersUnSelected = pageProviders
      .map((provider) => provider.dataset.providerId)

    let pageProvidersSelected = pageProviders
      .filter((provider) => this.isSelected(provider))
      .map((provider) => provider.dataset.providerId)

    let storageProvider = localStorage.getItem(`project-${this.storageIdValue}`)?.split(',') || []
    let addItems = storageProvider
      .filter((item) => !pageProvidersUnSelected.includes(item))

    addItems = [...addItems, ...pageProvidersSelected].filter((item) => item != '')

    this.selectedProviderTarget.value = addItems.join(',')
    this.selectedProviderSearchTarget.value = addItems.join(',')
    localStorage.setItem(`project-${this.storageIdValue}`, addItems.join(','))
  }

  buttonToggle(element) {
    if (this.isSelected(element)) {
      this.deSelectProvider(element)
    } else {
      this.selectProvider(element)
    }
  }

  isSelected(element) {
    return element.classList.contains("selected")
  }

  selectProvider(element) {
    element.classList.add("selected")
    element.textContent = element.dataset.deselectText
    element.closest('tr').classList.add('high-pri__background')
  }
  deSelectProvider(element) {
    element.classList.remove("selected")
    element.textContent = element.dataset.selectText
    element.closest('tr').classList.remove('high-pri__background')
  }

  disconnect() {
  }
}
