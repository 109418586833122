export class Toast extends HTMLElement {
  connectedCallback() {
    this.show()
  }

  show() {
    this.isVisible = true

    if (this.isDismissible) {
      this.addEventListener("click", () => {
        this.hide(0)
      });

    } else {
      this.hide(5000)
    }
  }

  hide(delay) {
    setTimeout(() => {
      this.isVisible = false
    }, delay)

    setTimeout(() => {
      this.remove();
    }, delay + 1000)
  }

  get isVisible() {
    return this.classList.contains("toast--visible")
  }

  set isVisible(visible) {
    if (visible) {
      this.classList.add("toast--visible")
    } else {
      this.classList.remove("toast--visible")
    }
  }

  get isDismissible() {
    return this.classList.contains("toast--dismissable")
  }
}
