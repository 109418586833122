import ApplicationController from "./application_controller";

export default class extends ApplicationController {
  connect() {
    let save_button = document.getElementById('saveDiscountButton');
    let company_select = document.getElementById('partner_price_discounts_list_new_provider_discount_eligible_company_id')

    company_select.addEventListener('change', () => {
      if (!document.getElementById('add_new_discount_table').classList.contains('!hidden')) {
        save_button.disabled = false;
      }
    })

    const valid_froms = document.querySelectorAll('#valid_from');
 
    valid_froms.forEach(valid_from => {
      valid_from.addEventListener('click', () => {
        save_button.disabled = false;
      });
    });

    const valid_untils = document.querySelectorAll('#valid_until');
 
    valid_untils.forEach(valid_until => {
      valid_until.addEventListener('click', () => {
        save_button.disabled = false;
      });
    });

    const discount_pcts = document.querySelectorAll('#discount_pct');

    discount_pcts.forEach(discount_pct => {
      discount_pct.addEventListener('change', () => {
        save_button.disabled = false;
      });
    });
  }

  addNewDiscount(e) {
    e.preventDefault();
    const add_new_discount_table = document.getElementById('add_new_discount_table');
    const add_new_discount_button = document.getElementById('add_new_discount_button');

    document.getElementById('saveDiscountButton').disabled = !document.getElementById('saveDiscountButton').disabled;

    add_new_discount_table.classList.toggle('!hidden')
    add_new_discount_button.classList.toggle('!hidden')

    document.getElementById('add_new_discount_on').value = !add_new_discount_table.classList.contains('!hidden')

  }

  saveNewDiscount(e) {
    e.preventDefault();

    let form = document.getElementById('provider_discounts_form')
    form.requestSubmit()
  }

  submitUpdateDiscountsForm(e) {
    e.preventDefault();

    let form = document.getElementById('update_provider_discounts_form')
    form.requestSubmit()
  }

  submitSearchForm(e) {
    e.preventDefault();

    let form = document.getElementById('search_form')
    form.requestSubmit()
  }
}
