import ApplicationController from "./application_controller";

/**
 * Toggles between view/edit mode of for sections.
 */
export default class extends ApplicationController {
  static targets = ["view", "edit"];

  connect() {
    super.connect();
    this.viewTarget.classList.remove("hidden");
    this.viewTarget.addEventListener('click', this.toggleEdit.bind(this));
    this.editTarget.classList.add("hidden");
    this.withFormElement(this.setupInput, this.setupSelect);
  }

  toggleEdit(event) {
    this.editTarget.classList.remove("hidden");
    this.viewTarget.classList.add("hidden");
    this.withFormElement(this.editInput, this.editSelect);
  }

  toggleView(event) {
    this.editTarget.classList.add("hidden");
    this.viewTarget.classList.remove("hidden");
    this.withFormElement(this.viewInput, this.viewSelect);
  }

  withFormElement(cb_input, cb_select) {
    let input = this.editTarget.getElementsByTagName('input');
    if (input.length > 0) {
      return cb_input(this, input[0]);
    }
    let select = this.editTarget.getElementsByTagName('select');
    if (select.length > 0) {
      return cb_select(this, select[0]);
    }
  }

  setupInput(self, e) {
    e.addEventListener('focusout', self.toggleView.bind(self));
    self.viewInput(self, e);
  }

  setupSelect(self, e) {
    e.addEventListener('change', self.toggleView.bind(self));
    self.viewSelect(self, e);
  }

  editInput(self, e) {
    e.selectionStart = 0;
    e.selectionEnd = e.value.length;
    e.focus();
  }

  editSelect(self, e) {

  }

  viewInput(self, e) {
    self.viewTarget.innerText = e.value;
  }

  viewSelect(self, e) {
    self.viewTarget.innerText = e.options[e.selectedIndex].text;
  }
}
