import ApplicationController from "./application_controller";

export default class extends ApplicationController {
  static targets=["processType", "serviceArea"]

  connect() { this.disabled_field() }

  disabled_field() {
    if (this.processTypeTarget.value.toString() == "off_site" ) {
      this.serviceAreaTarget.disabled = false
    }
    else {
      this.serviceAreaTarget.disabled = true
    }
  }

}
