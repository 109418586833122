import ApplicationController from "./application_controller";

/**
 * The `ToggleController` is a utility controller that allows toggling classes
 * on all its content targets.
 *
 * When there is no transition class name applied, the controller will use the
 * default class name (e.g. `d--none`) for toggling the content targets.
 *
 * @example
 *  <div data-controller="toggle">
 *    <button type="button" data-action="click->toggle#toggle">Toggle</button>
 *
 *    <div data-toggle-target="content">
 *      ...
 *    </div>
 *  </div>
 *
 * It can also be used with a specific transition class name. In that case, define
 * the transition class name on the controller element.
 *
 * @example
 *  <div data-controller="toggle" data-toggle-transition-class="some-class">
 *    <button type="button" data-action="click->toggle#toggle">Toggle</button>
 *
 *    <div data-toggle-target="content">
 *      ...
 *    </div>
 *  </div>
 */

export default class extends ApplicationController {
  static targets = ['content']
  static classes = ['transition']

  toggle() {
    this.contentTargets.forEach(el => el.classList.toggle(this.transitionClassName))
  }

  /**
   * The `transitionClassName` determines what class name should be used for the
   * transition between the different toggle states.
   * @returns {String} The transition class
   */

  get transitionClassName() {
    if (this.hasTransitionClass) return this.transitionClass

    return 'hidden'
  }
}
