import ApplicationController from "./application_controller";

// Connects to data-controller="modal"
export default class extends ApplicationController {
  static values = { initUrl: String }

  connect() {
    // query initial modal visibility:
    this.isModalVisible = (this.element.getAttribute('aria-hidden') !== 'true'); // this is managed by MicroModal itself
    // if modal is visible, load content into turbo-frame:
    if (this.isModalVisible) {
      this.reloadContent();
    }
    // subscribe to modal visibility changes:
    this.openedObserver = new MutationObserver(this.onModalAttributeChanged.bind(this));
    this.openedObserver.observe(this.element, { attributes: true, childList: false, subtree: false });
  }

  onModalAttributeChanged(_mutationList, _observer) {
    let currentModalVisibility = (this.element.getAttribute('aria-hidden') !== 'true');
    // modal just changed:
    if (currentModalVisibility !== this.isModalVisible) {
      if (currentModalVisibility) { // modal opened: refresh content
        this.reloadContent();
      } else { // modal closed: clear content
        this.clearContent();
      }
    }
    // store state:
    this.isModalVisible = currentModalVisibility;
  }

  reloadContent() {
    let turboFrame = this.element.getElementsByTagName('turbo-frame')[0];
    turboFrame.src = this.initUrlValue;
  }

  clearContent() {
    let turboFrame = this.element.getElementsByTagName('turbo-frame')[0];
    turboFrame.innerHTML = ''; // this clears the modal content, so no previous state is shown on the next open.
  }
}
