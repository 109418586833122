import { ElementObserver } from "../helpers/element_observer"

export class Autoresizer {
  static SELECTOR  = "textarea"

  static start() {
    this.observer = new ElementObserver(document, this.SELECTOR, new Autoresizer())
    this.observer.refresh()
    this.observer.start()
  }

  constructor() {
    this.boundInputHandler = this.handleInput.bind(this)
  }

  selectorMatched(element) {
    this.autoresize(element)
    element.addEventListener('input', this.boundInputHandler)
  }

  matchedSelectorRemoved(element) {
    element.removeEventListener('input', this.boundInputHandler)
  }

  handleInput(event) {
    this.autoresize(event.currentTarget)
  }

  async autoresize(element) {
    await this.nextFrame()

    if (element.dataset.minHeight == null) {
      element.dataset.minHeight = element.clientHeight
    }

    element.style.height = null

    const offset = element.offsetHeight - element.clientHeight
    const newHeight = element.scrollHeight + offset
    if (newHeight > element.dataset.minHeight) {
      element.style.height = `${element.scrollHeight + offset}px`
    }
  }

  nextFrame() {
    return new Promise(requestAnimationFrame)
  }
}
