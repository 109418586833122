import ApplicationController from "./application_controller";
import { get } from "@rails/request.js";

export default class extends ApplicationController {
  static targets = ["dataSet", "language"]
  static values = {
    url: String,
    url2: String
  }
  connect() {
    let languageSelect = this.languageTarget.options
    let targets = this.dataSetTarget.id
    let cold_target = document.getElementById('cold_vendor_template_target')
    let last_selected = document.getElementById("selected_email_template_id")
    let last_target_selected = document.getElementById("selected_vendor_template_id")
    if(last_selected || last_target_selected) {
      let query_builder = `target=${targets}&language=${languageSelect[languageSelect.selectedIndex].value}`
      if (cold_target) query_builder += `&cold_target=${cold_target.id}`
      if (last_selected) query_builder+= `&last_selected=${last_selected.getAttribute("value")}`
      if (last_target_selected) query_builder+= `&last_cold_selected=${last_target_selected.getAttribute("value")}`
      get(`${this.urlValue}?${query_builder}`, {
        responseKind: "turbo-stream"
      })
    } else {
      get(`${this.urlValue}?target=${targets}&language=${languageSelect[languageSelect.selectedIndex].value}`, {
        responseKind: "turbo-stream"
      })
    }
  }

  emailTemplateSelected(event){
    let orderId = document.getElementById('order_id_for_template')
    let last_selected = event.target.options
    let query_builder = ``
    if (orderId) query_builder += `order_id=${orderId.getAttribute("value")}`
    if (query_builder.length > 0 && last_selected) query_builder += `&`
    if (last_selected) query_builder += `last_selected=${last_selected[last_selected.selectedIndex].value}`

    get(`${this.url2Value}?${query_builder}`, {
      responseKind: "turbo-stream"
    })
  }

  changeTemplate(event) {
    let orderId = document.getElementById('order_id_for_template')
    let languageSelect = this.languageTarget.options
    let targets = this.dataSetTarget.id
    let query_builder = `target=${targets}&language=${languageSelect[languageSelect.selectedIndex].value}`
    let cold_target = document.getElementById('cold_vendor_template_target')
    if (cold_target) query_builder += `&cold_target=${cold_target.id}`
    if (orderId) query_builder += `&order_id=${orderId.getAttribute("value")}`
    get(`${this.urlValue}?${query_builder}`, {
      responseKind: "turbo-stream"
    })
  }
}
