import ApplicationController from "./application_controller";
import { meta } from "../helpers/meta";
export default class extends ApplicationController {
  static acceptedFileTypes = ["image/jpeg", "image/png", "image/gif"]

  fileInserted(event) {
    let maxFileSize = 5000000
    let file = event.file

    if (!this.constructor.acceptedFileTypes.includes(file.type) ||
        file.size > maxFileSize) {
      event.preventDefault()
      alert(meta("trix:invalid_file_error"))
    }
  }
}
