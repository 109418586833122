import ApplicationController from "./application_controller";
import consumer from "../channels/consumer";
import { get } from "@rails/request.js";

// Connects to data-controller="project-matching-progress"
export default class extends ApplicationController {
  static values = {
    oid: String,
    redirect: String
  };

  connect() {
    const redirectFunc = this.redirectToNextScreen.bind(this);
    const application = this
    consumer.subscriptions.create(
      { channel: "ProjectMatchingProgressChannel", project_oid: this.oidValue},
      {
        async connected() {
          const { json } = await get(`/projects/${application.oidValue}/vendors/status`)
          const data = await json;
          if (data.status == 'expire' || data.status == 'success') redirectFunc();
        },
        disconnected() { },
        received(data) { redirectFunc(); }
      }
    );
  }

  redirectToNextScreen() {
    window.location = this.redirectValue;
  }
}
