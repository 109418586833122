import ApplicationController from "./application_controller";

export default class extends ApplicationController {
  static targets = ["trigger", "action"];

  triggerTargetConnected(element) {
    const boundOnChange = this.onChange.bind(this);
    element.addEventListener("change", boundOnChange);
    element.addEventListener("keyup", boundOnChange);
  }

  onChange() {
    this.actionTargets.forEach(function(element) { element.disabled = false });
  }
}
