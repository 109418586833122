import ApplicationController from "./application_controller";

export default class extends ApplicationController {
  static targets = [ 'sidebar', 'main' ]

  connect() {
    if (localStorage.getItem('sidebar-state') == 'collapsed') {
      this.applyClosedState();
    } else {
      this.applyOpenedState();
    }
  }

  applyClosedState() {
    this.sidebarTarget.style = "transition: none !important;";
    this.mainTarget.style = "transition: none !important;";
    this.sidebarTarget.classList.add("sidebar--collapsed");
  }

  applyOpenedState() {
    this.sidebarTarget.classList.add("sidebar--opened");
    this.sidebarTarget.style = "transition: none !important;";
    this.mainTarget.style = "transition: none !important;";
  }

  toggleSidebar(event) {
    this.sidebarTarget.style = "";
    this.mainTarget.style = "";
    localStorage.getItem('sidebar-state') == 'collapsed' ? this.openSidebar() : this.closeSidebar();
  }

  openSidebar() {
    this.sidebarTarget.classList.remove("sidebar--collapsed");
    localStorage.setItem('sidebar-state', 'expanded');
  }

  closeSidebar() {
    this.sidebarTarget.classList.add("sidebar--collapsed");
    localStorage.setItem('sidebar-state', 'collapsed');
  }
}
