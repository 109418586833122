import ApplicationController from "./application_controller";

export default class extends ApplicationController {
  static targets = [ 'input' ]

  increment(event) {
    event.preventDefault();

    const date = new Date(this.inputTarget.value);
    const daysToAdd = parseInt(event.currentTarget.dataset.incrementBy) || 0;
    const newDate = this.dateWithDaysAdded(date, daysToAdd);

    this.inputTarget.value = newDate.toISOString().substring(0,10);
    this.inputTarget.dispatchEvent(new CustomEvent('change'))
  }

  dateWithDaysAdded(date, nDays) {
    var result = new Date(date);
    result.setDate(result.getDate() + nDays);
    return result;
  }
}
