import Suggestions from "suggestions";
import ApplicationController from "./application_controller";
import {get} from "@rails/request.js";

export default class extends ApplicationController {
  static targets = [ 'input', 'idInput' ]
  static values = { suggestions: Array, idProperty: String, nameProperty: String, urlProperty: String }

  async connect() {
    if(this.hasUrlPropertyValue) {
      let response = await get(this.urlPropertyValue, {})
      const { body } = await response.json
      this.suggestionsValue = body

      this.suggestions = new Suggestions(this.suggestInput(), this.suggestionsValue, {
        render: this.renderSuggestionItem.bind(this),
        getItemValue: this.suggestionItemValue.bind(this)
      })
    } else {
      this.suggestions = new Suggestions(this.suggestInput(), this.suggestionsValue, {
        render: this.renderSuggestionItem.bind(this),
        getItemValue: this.suggestionItemValue.bind(this)
      })
    }
  }

  suggestInput() {
    return this.hasInputTarget ? this.inputTarget : this.element;
  }

  renderSuggestionItem(item) {
    if (!this.hasNamePropertyValue) return item;

    return item[this.namePropertyValue].toString();
  }

  suggestionItemValue(item) {
    if (!this.hasNamePropertyValue) return item;

    return item[this.namePropertyValue].toString();
  }

  chooseSuggestion(event) {
    this.suggestInput().dispatchEvent(
      new CustomEvent('suggest:chosen', { detail: { object: this.suggestions.selected } })
    );

    if (this.suggestions.selected && this.hasIdInputTarget &&  this.hasIdPropertyValue) {
      this.idInputTarget.value = this.suggestions.selected[this.idPropertyValue];
    } else if (this.hasIdInputTarget) {
      this.idInputTarget.value = '';
    } else {

    }
    delete this.suggestions['selected']
  }
}
