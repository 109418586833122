import { parseIntlFloat } from "../helpers/number_helper"
import ApplicationController from "./application_controller";

export default class extends ApplicationController {
  static targets = ['factor', 'percentDiscount', 'result']
  static values = {
    locale: String,
    currency: String
  }

  connect() {
    this.updateResult();
  }

  updateResult(event) {
    if (this.missingTargets) return;
    if (event && event.target && event.target == this.percentDiscountTarget) {
      this.percentDiscountTarget.value = parseIntlFloat(this.percentDiscountTarget.value);
    }

    if (event && event.target && event.target == this.factorTarget) {
      this.factorTarget.value = parseIntlFloat(this.factorTarget.value);
    }

    this.resultTarget.value = this.discountAmount;
    this.resultTarget.dispatchEvent(new CustomEvent('change'));
  }

  get discountAmount() {
    const factor = parseIntlFloat(this.factorTarget.value) || 0;
    const decimalDiscount = (parseIntlFloat(this.percentDiscountTarget.value) || 0);
    const percentDiscount = decimalDiscount / 100.0;
    const discount = factor * percentDiscount;

    return (factor - discount).toFixed(2);
  }

  get hasAllTargets() {
    return (this.hasFactorTarget && this.hasPercentDiscountTarget && this.hasResultTarget);
  }

  get missingTargets() {
    return !this.hasAllTargets;
  }
}
