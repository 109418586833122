import ApplicationController from "./application_controller";
import SlimSelect from "slim-select";
import {post} from "@rails/request.js";

// Connects to data-controller="linked-autocomplete"
export default class extends ApplicationController {
  static values = { firstLoadUrl: String, url: String, extra: String, placeholderItem: String, rejectMessage: String, addable: Boolean }
  static outlets = [ "autocomplete" ]

    connect() {
    setTimeout(async () => {
      const { urlValue, extraValue, rejectMessageValue, addableValue } = this
      const element = this.hasAutocompleteOutlet ? this.autocompleteOutlet.element : undefined
      const events = {
        ...(urlValue) && {
          search: function (search, currentData) {
            return new Promise(async (resolve, reject) => {
              if (search.length < 2 && search.length > 0) return reject('Search must be at least 2 characters')
              if (!element.value) return reject(rejectMessageValue)
              const  { json } = await post(`${urlValue}?keyword=${search}&${extraValue}&test_definition_id=${element.value}` )
              let data = await json
              resolve([
                { 'placeholder': true, 'text': this.placeholderItemValue || '', value: '' },
                ...data.map((item) => ({ text: item.name, value: item.id }))
              ])
            })
          }
        },
        ...(addableValue) && { addable: function (value) { return value } }
      }

      let allowDeselect = (this.element.getAttribute('multiple') != null)

      const linkedAutocompleteSlim = new SlimSelect({
        select: this.element,
        events: events,
        settings: {
          openPosition: 'down',
          allowDeselect: this.element.multiple
        },
      })

      if(element) {
        element.addEventListener('change', async () => {
          if (element.value) {
            const {json} = await post(`${urlValue}?${extraValue}&test_definition_id=${element.value}`)
            let data = await json
            linkedAutocompleteSlim.setData(
              [
                {
                  'placeholder': true,
                  'text': this.placeholderItemValue || '',
                  value: ''
                },
                ...data.map((item) => ({text: item.name, value: item.id}))
              ]
            )
          }
        })
        if (this.hasFirstLoadUrlValue) {
          if (element.value) {
            const {json} = await post(`${urlValue}?${extraValue}&test_definition_id=${element.value}`)
            let data = await json
            linkedAutocompleteSlim.setData(
              [
                {
                  'placeholder': true,
                  'text': this.placeholderItemValue || '',
                  value: ''
                },
                ...data.map((item) => ({text: item.name, value: item.id}))
              ]
            )
          }
        }
      }
    }, 0)
  }
}
