import ApplicationController from "./application_controller";

/**
 * Toggles a section if a select-option value is "custom"
 *
 * <div data-controller="toggle-custom"
 *    data-toggle-custom-transition-class="absolute left-[-9999px]"
 *    data-toggle-custom-matcher-value="custom_requirement">
 *
 *   <select data-toggle-custom-target="source" ...> ... </select>
 *
 *   <div data-toggle-custom-target="content" ...> ... </div>
 * </div>
 */
export default class extends ApplicationController {
  static targets = ["source", "content"];
  static classes = ["transition"];
  static values = { matcher: String, default: "custom" };

  connect() {
    this.sourceTargets.forEach(element => {
      element.addEventListener('change', this.change.bind(this));
      this.change({target: element});
    });
  }

  change(event) {
    const regexp = new RegExp(this.matcherValue);
    if (regexp.test(event.target.value)) {
      this.contentTargets.forEach(el => el.classList.remove(this.transitionClassName));
    } else {
      this.contentTargets.forEach(el => el.classList.add(this.transitionClassName));
    }
  }

  get transitionClassName() {
    return (this.hasTransitionClass) ? this.transitionClass : "hidden";
  }
}
