import Litepicker from 'litepicker';
import ApplicationController from "./application_controller";

export default class extends ApplicationController {
  static targets = [ 'input' ]
  static values = {
    earliestDate: String,
    preselectedDate: String,
    enableResetButton: { type: Boolean, default: true }
  }

  connect() {
    this.inputTarget.addEventListener('click', this.preventDefaultBehavior)
    this.inputTarget.addEventListener('keyup', this.preventDefaultBehavior)
    this.inputTarget.addEventListener('keydown', this.preventDefaultBehavior)
    new Litepicker({
      element: this.inputTarget,
      minDate: this.earliestDate || this.currentDate,
      startDate: this.inputTarget.value || this.preselectedDate,
      parentEl: this.inputTarget.parentNode,
      setup: this.setupLitepicker,
      resetButton: !this.inputTarget.required && this.enableResetButtonValue
    })
  }

  setupLitepicker(picker){
    picker.on('show', (el) => {
      let picker = el.parentNode.querySelector('.litepicker')
      if(el.getBoundingClientRect().y > 620) {
          if(!picker.classList.contains('fix')) {
            picker.classList.add('fix')
          }
          if(!picker.classList.contains('up')) {
          picker.classList.add('up')
        }
      } else {
        if(!picker.classList.contains('fix')) {
          picker.classList.add('fix')
        }
        if(picker.classList.contains('up')) {
          picker.classList.remove('up')
        }
      }
    });
  }
  get currentDate() {
    let date = new Date();
    date.setHours(0, 0, 0, 0);
    return date;
  }

  get preselectedDate() {
    return this.dateOrNull(this.preselectedDateValue);
  }

  get earliestDate() {
    return this.dateOrNull(this.earliestDateValue);
  }

  dateOrNull(value) {
    if (value && value != '') {
      return new Date(value)
    }

    return null
  }

  preventDefaultBehavior(event) {
    event.preventDefault();
  }

}
