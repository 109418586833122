import ApplicationController from "./application_controller";

export default class extends ApplicationController {
  static targets = ['hideable'];

  connect() {
    this.hiddenClass = 'hidden';
    if (this.data.has('class')) {
      this.hiddenClass = this.data.get('class');
    }
  }

  toggle(event) {
    this.hideableTargets.forEach((node) => node.classList.toggle(this.hiddenClass));
  }
}
