import NestedForm from "stimulus-rails-nested-form"

// Connects to data-controller="offer-indicative-table-nested-form"
export default class extends NestedForm {
  connect() {}
  add(e) {
    e.preventDefault();
    const content = this.templateTarget.innerHTML.replace(/OFFER_INDICATIVE_TABLE/g, new Date().getTime().toString());
    this.targetTarget.insertAdjacentHTML("beforebegin", content);
  }
  remove(e) {
    this.element.dispatchEvent(new CustomEvent('nested-form:removed', { bubbles: true }));
    super.remove(e);
  }
}
