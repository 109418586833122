import ApplicationController from "./application_controller";
import {post} from "@rails/request.js";

// Connects to data-controller="valid-step"
export default class extends ApplicationController {
  static targets = ['formData']
  static values = {
    validUrl: String
  }

  updateStatusStepper(e) {
    let form = new FormData(this.formDataTarget)
    post(`${this.validUrlValue}`, { body: form, responseKind: 'turbo-stream' })
  }
}
