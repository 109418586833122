import CurrencyHelper from "../helpers/currency_helper"
import ApplicationController from "./application_controller";

export default class extends ApplicationController {
  static targets = [ 'number', 'sum', 'status' ];
  static values = { formatCurrency: Boolean, formatCurrencyLocale: String, formatCurrencyUnit: String };

  connect() {
    this.updateSum();
  }

  updateSum() {
    setTimeout(() => {
      if (this.hasSumTarget) {
        let sum = 0
        if (this.hasNumberTarget) {
          const numbers = this.numberTargets
                              .filter((item) => item.closest('tbody').style.display !== 'none')
                              .map(target => parseFloat(target.textContent) || 0.0);
          let status = this.statusTargets.filter((item) => item.nodeName == 'INPUT' && item.closest('tbody').style.display !== 'none')
          
          for(let i = 0; i < numbers.length; i++) {
            if (status[i]?.closest('tbody').classList.contains('sortable-drag')) continue;
            if(status[i]?.checked) sum += numbers[i];
          }
        }
        if (this.formatCurrencyValue) {
          this.sumTarget.textContent = CurrencyHelper.formatCurrency(
            this.formatCurrencyLocaleValue, this.formatCurrencyUnitValue, sum
          );
        } else {
          this.sumTarget.textContent = CurrencyHelper.asNumber(sum);
        }
      }
    }, 0)
  }
}
