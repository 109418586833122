import ApplicationController from "./application_controller";
import consumer from "../channels/consumer";
import { get } from "@rails/request.js";

// Connects to data-controller="action-cabled-frame"
export default class extends ApplicationController {
  static values = {
    channel: String,
    key: String,
  };

  connect() {
    const reloadFrameFunc = this.reloadFrame.bind(this);
    const application = this;

    consumer.subscriptions.create(
      {channel: application.channelValue, key: application.keyValue},
      {
        disconnected() {},
        received(data) { reloadFrameFunc(); }
      }
    );
  }

  reloadFrame() {
    // reload turbo frame:
    this.element.reload();
  }
}
