import ApplicationController from './application_controller'
import Litepicker from "litepicker";
import {get} from "@rails/request.js";

// Connects to data-controller="classifiable-table-helper-date-range-picker"
export default class extends ApplicationController {
  static targets = ["pickerElement", "url", "model", "attribute"]
  connect() {
    this.pickerElementTarget.addEventListener('click', this.preventDefaultBehavior)
    this.pickerElementTarget.addEventListener('keyup', this.preventDefaultBehavior)
    this.pickerElementTarget.addEventListener('keydown', this.preventDefaultBehavior)
    const picker = new Litepicker({
      element: this.pickerElementTarget,
      autoRefresh: true,
      // autoApply: false,
      // useResetBtn: true,
      // buttonText: {
      //   apply: "Done",
      //   reset: "Reset"
      // },
      tooltipText: {
        one: "day",
        other: "days"
      },
      moduleRanges: false,
      numberOfMonths: 2,
      numberOfColumns: 2,
      singleMode: false,
      // moduleNavKeyboard: true
      // minDate: Date.now(),
      // startDate:  Date.now()
      setup: (picker) => {
        picker.on("selected", (date1, date2) => {
          let value = this.urlTarget.getAttribute("value");
          let model = this.modelTarget.getAttribute("value");
          let attribute = this.attributeTarget.getAttribute("value");
          get(`${value}?model=${model}&attribute=${attribute}&dateFrom=${new Date(date1.toLocaleString()).toISOString()}&dateTo=${new Date(date2.toLocaleString()).toISOString()}`, {
            responseKind: "turbo-stream"
          })
        });
        picker.on('hide', () => {
          picker.clearSelection()
        });
      },
    })
  }

  preventDefaultBehavior(e) {
    e.preventDefault()
  }
}
