import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="estimate-create-plans"
export default class extends Controller {
  connect() {
    let changeData = document.getElementById("Estimated_amount")
    let tender_estimated_amount = document.getElementById("tender_estimated_amount")
    let data = tender_estimated_amount.value;
    changeData.value = data
    this.updateSum()
  }

  updateDataValue() {
    let changeData = document.getElementById("Estimated_amount")
    let tender_estimated_amount = document.getElementById("tender_estimated_amount")
    let tender_estimate_amount_tender = document.getElementById("tender_estimate_amount_tender")
    let data = changeData.value;
    tender_estimate_amount_tender.value = data
    tender_estimated_amount.value = data
    this.updateSum()
  }

  updateSum() {
    let submit_estimated_button = document.getElementById("submit_estimated_button")
    let inputLowerThanZero = false
    let tender_estimated_amount = document.getElementById("tender_estimated_amount")
    let data = tender_estimated_amount.value;
    let sum = 0;

    let group_estimate_field_year = ['first', 'second', 'third']
    let group_estimate_field_month = ['jan', 'feb', 'mar', 'apr', 'may', 'jun', 'jul', 'aug', 'sep', 'oct', 'nov', 'dec']

    group_estimate_field_year.forEach((year) => {
      group_estimate_field_month.forEach((month) => {
        let tender_estimate_amount = document.getElementById(`tender_${year}_year_${month}_estimate_amount`)
        if (tender_estimate_amount.value) sum += parseFloat(tender_estimate_amount.value)
        if (parseFloat(tender_estimate_amount.value) < 0) inputLowerThanZero = true
      })
    })
    
    let remainingPlan = document.getElementById("Remaining_amount_to_plan")
    remainingPlan.value = (data - sum).toFixed(4)
    let error_field = document.getElementById("error_field")
    if ( data - sum != 0 || inputLowerThanZero ) {
      if(!submit_estimated_button.classList.contains("d-none")) submit_estimated_button.classList.add('d-none')
    } else {
      if(submit_estimated_button.classList.contains("d-none")) submit_estimated_button.classList.remove('d-none')
    }
    if (inputLowerThanZero) {
      error_field.innerText = "You must input text bigger than 0"
    } else {
      error_field.innerText = ""
    }
  }

  preventAccidentalFormSubmit(e) {
    e.preventDefault();
  }
}
