import NestedForm from "stimulus-rails-nested-form"

export default class extends NestedForm {
  static targets = ["device", "customDeviceLocation", "deviceLocation", "customDeviceLocationField", "deviceLocationField"];

  connect() {
    if(this.deviceTargets.length == 0) {
      const content = this.templateTarget.innerHTML.replace(/NEW_RECORD/g, new Date().getTime().toString());
      this.targetTarget.insertAdjacentHTML("beforebegin", content);
    }
  }

  add(e) {
    super.add(e);
  }

  remove(e) {
    if (this.deviceTargets.length > 1) {
      super.remove(e);
    }
    this.element.dispatchEvent(new CustomEvent('nested-form-remove'));
  }

  hideDeviceLocation(e) {
    if (e.target.checked) {
      this.customDeviceLocationTarget.classList.remove('hidden')
      this.customDeviceLocationFieldTargets.map((item) => (item.disabled = false) )

      this.deviceLocationTarget.classList.add('hidden')
      this.deviceLocationFieldTarget.disabled = true

    } else {
      this.customDeviceLocationTarget.classList.add('hidden')
      this.customDeviceLocationFieldTargets.map((item) => (item.disabled = true) )

      this.deviceLocationTarget.classList.remove('hidden')
      this.deviceLocationFieldTarget.disabled = false

    }
  }
}
