import ApplicationController from "./application_controller";

export default class extends ApplicationController {
  static values = { className: String };

  switchToTab(event) {
    event.preventDefault();

    const { tabId } = event.target.closest('li').dataset;
    if (tabId === this.activeTabId) return;

    this.activeTabId = tabId;
  }

  connect() {
    this.activeTabId = this.activeTabId;
  }

  get activeTab() {
    return this.element.querySelector(`ul.tabs li.${this.classNameValue}`);
  }

  get activeTabId() {
    const { tabId } = this.activeTab.dataset;
    return tabId;
  }

  set activeTabId(id) {
    this.element.querySelectorAll('ul.tabs li').forEach(el => {
      const { tabId } = el.dataset;
      el.classList.toggle(this.classNameValue, tabId === id);
    });

    this.element.querySelectorAll('[data-tab-content-id]').forEach(el => {
      const { tabContentId } = el.dataset;
      el.classList.toggle(this.classNameValue, tabContentId === id);
    });
  }
}
