import ApplicationController from "./application_controller";
import InputErrorsHelper from "../helpers/input_errors_helper";
import { get } from "@rails/request.js";

// Connects to data-controller="onboarding-company-setup"
export default class extends ApplicationController {

  static targets = [ "companyId" ]

  async company_exists_check(event) {
    let input = event.srcElement
    let field_name = input.id.substring('company_'.length)
    let company_id = this.companyIdTarget.value

    if(document.getElementById(`company_role`).value == 'provider') {
      let response = await get(`/users/onboarding/company_details/exists?${field_name}=${input.value}&company_id=${company_id}`, {})
      const body = await response.json

      if(body.exists) {
        document.getElementById('support-button').classList.remove('!hidden')
        document.getElementsByClassName('existing-company-hint')[0].classList.remove('hidden')
        document.getElementById('checkboxes').classList.add('hidden')
        document.getElementsByClassName('actions')[0].style.display = 'none'
        document.getElementsByClassName(`${field_name}-field`)[0].classList.add('warning-email-field')
      } else {
        document.getElementsByClassName(`${field_name}-field`)[0].classList.remove('warning-email-field')
        if(document.getElementsByClassName('warning-email-field').length == 0) {
          document.getElementById('support-button').classList.add('!hidden')
          document.getElementsByClassName('existing-company-hint')[0].classList.add('hidden')
          document.getElementById('checkboxes').classList.remove('hidden')
          document.getElementsByClassName('actions')[0].style.display = 'block'
        }
      }
    }
  }

  async validateForm(event) {
    event.preventDefault()

    const prefix = 'company_'
    const requiredCheckboxes = ['terms', 'privacy_policy', 'provider_terms_and_conditions', 'customer_terms_and_conditions']
    const checkboxError = !InputErrorsHelper.showCantBeUncheckedError(prefix, requiredCheckboxes)

    if (checkboxError) {
      document.getElementById('company-details-form').submit()
    }
  }
}
