import ApplicationController from "./application_controller"

// Connects to data-controller="filter-helper"
export default class extends ApplicationController {

  /**
   * Function to apply the value of a select filter to the query string
   * It gets the value of the selected option of the html select,
   * appends/overwrites/removes the filter parameter in the query string and
   * opens the url with the new parameters
   *
   * @param event
   */
  applySelectFilter(event) {
    const filterName = event.target.name,
      filterValue = event.target.value,
      url = new URL(window.location.href),
      urlParams = url.searchParams

    // If teh selected option value is '', remove the param
    if ( !filterValue ) {
      urlParams.delete(filterName)
      // If the query string already contains the param, overwrite it
    } else if (urlParams.has(filterName)) {
      urlParams.set(filterName, filterValue)
      // Otherwise append the new param to the query string
    } else {
      urlParams.append(filterName, filterValue)
    }

    // redirect browser to new url
    window.location = url.href
  }
}
