import ApplicationController from "./application_controller";

export default class extends ApplicationController {
  debounceSubmit(event) {
    let form = event.currentTarget.form
    clearTimeout(this.timeout)

    this.timeout = setTimeout(() => {
      form.requestSubmit()
    }, 1000);
  }

  submit(event) {
    event.currentTarget.form.requestSubmit()
  }
}
