import ApplicationController from './application_controller'

// Connects to data-controller="company-nested-roles"
export default class extends ApplicationController {
  static targets = ['roleId', 'destroy', 'group', 'subgroup', 'target', 'template', 'companyRole', 'item']
  static values = { associated: String, notAssociated: String }

  add(e, index = 0) {
    e.preventDefault();
    const content = this.templateTarget.innerHTML
      .replace(/NEW_COMPANY_ROLE/g, new Date().getTime().toString() + index * 200)

    this.targetTarget.insertAdjacentHTML("beforebegin", content);
  }

  updateCompanyRole(e) {
    let [group, subgroup] = e.target.dataset.id.split('.')

    if (!subgroup) {
      let group_permisson = e.target.closest('#group-permission')

      this.disableGroup(e.target.checked, group_permisson, this.associatedValue, this.notAssociatedValue)

      this.itemTargets.forEach((item) => {
        if (item.nodeName == "INPUT" && item.dataset.id.split('.')[0] == group) {
          if (e.target.checked) {
            item.disabled = false
            document.querySelector(`[data-text-id="${item.dataset.id}"]`).classList.remove('text-light-grey-120')
          } else {
            item.disabled = item.dataset.id.split('.')[1] ? true : false
            item.checked = false
            if(item.dataset.id != e.target.dataset.id) {
              let roleExists = this.companyRoleTargets.find((x) =>
                x.querySelector('[data-company-nested-roles-target=group]').value + '.' + x.querySelector('[data-company-nested-roles-target=subgroup]').value == item.dataset.id)

              if (roleExists) {
                if (roleExists.querySelector('[data-company-nested-roles-target=roleId]').value) {
                  roleExists.querySelector('[data-company-nested-roles-target=destroy]').value = true
                } else {
                  roleExists.remove()
                }
              }
            }
            document.querySelector(`[data-text-id="${item.dataset.id}"]`).classList.add('text-light-grey-120')
            document.querySelector(`[data-text-id="${item.dataset.id}"]`).classList.remove('font-bold')
          }
        }
      })
    }
    let companyRole = this.companyRoleTargets.find((x) => (x.querySelector('[data-company-nested-roles-target=group]').value + '.' + x.querySelector('[data-company-nested-roles-target=subgroup]').value) == e.target.dataset.id)

    if (!companyRole) {
      const targetsLength = this.companyRoleTargets.length
      this.add(e)
      this.groupTargets[targetsLength].value = group
      this.subgroupTargets[targetsLength].value = subgroup
      document.querySelector(`[data-text-id="${e.target.dataset.id}"]`).classList.add('font-bold')
    } else {
      if (e.target.checked) {
        if (companyRole.querySelector('[data-company-nested-roles-target=roleId]').value) {
          companyRole.querySelector('[data-company-nested-roles-target=destroy]').value = false
        }
        document.querySelector(`[data-text-id="${e.target.dataset.id}"]`).classList.add('font-bold')
      } else {
        if (companyRole.querySelector('[data-company-nested-roles-target=roleId]').value) {
          companyRole.querySelector('[data-company-nested-roles-target=destroy]').value = true
        } else {
          companyRole.remove()
        }
        document.querySelector(`[data-text-id="${e.target.dataset.id}"]`).classList.remove('font-bold')
      }
    }
  }

  disableGroup(checked, element, checked_value, unchecked_value) {
    let flash_group_permission = element.querySelector('span.project-tag')
    let hr_permisssion = element.querySelector('hr')
    if (checked) {
      if (hr_permisssion) {
        hr_permisssion.classList.add('border-grey-140')
      }
      flash_group_permission.classList.add('bg-avocado-40', 'text-black')
      flash_group_permission.classList.remove('bg-tangerine-40', 'text-orange')
      flash_group_permission.querySelector('svg').classList.remove('hidden')
      flash_group_permission.querySelector('span').textContent = checked_value
      element.classList.add('border-grey-160')
      element.classList.remove('border-light-grey-100')
    } else {
      if (hr_permisssion) {
        hr_permisssion.classList.remove('border-grey-140')
      }
      flash_group_permission.classList.remove('bg-avocado-40', 'text-black')
      flash_group_permission.classList.add('bg-tangerine-40', 'text-orange')
      flash_group_permission.querySelector('svg').classList.add('hidden')
      flash_group_permission.querySelector('span').textContent = unchecked_value
      element.classList.add('border-light-grey-100')
      element.classList.remove('border-grey-160')
    }
  }
}
