import { Controller } from "@hotwired/stimulus"
import InputErrorsHelper from "../helpers/input_errors_helper";

export default class extends Controller {
  static targets = ['newCustomerForm']

  handleValidation(inputTarget, regex, errorClass) {
    const errorElement = inputTarget.nextElementSibling;
    let showError = false;

    if (errorElement && errorElement.classList.contains(errorClass)) {
      errorElement.remove();
    }

    if (!regex.test(inputTarget.value)) {
      const message = document.createElement('p');
      message.classList.add(errorClass);
      message.classList.add('field_error');
      message.textContent = 'Can contain characters, numbers, and hyphens or dots only';

      inputTarget.parentNode.insertBefore(message, inputTarget.nextSibling);
      inputTarget.classList.add('onboarding-required-input');
      showError = true;
    } else {
      inputTarget.classList.remove('onboarding-required-input');
    }

    return showError;
  }

  async validateForm(event) {
    event.preventDefault();
    let requiredFields = ['reg_id_regexp_input', 'tax_id_regexp_input', 'name', 'city', 'zip_code', 'address1', 'country']
    let requiredSelect = ['company_headquarters_address_attributes_country']
    InputErrorsHelper.showCantBeBlankError('', requiredSelect)

    if(!InputErrorsHelper.showCantBeBlankError('new_customer_', requiredFields) && !InputErrorsHelper.showCantBeBlankError('', requiredSelect))
    {
      document.getElementById('new_customer_form').submit();
    }
  }
}
