import ApplicationController from "./application_controller";
import { showToast } from "../helpers/toasts_helper"
export default class extends ApplicationController {
  static targets = [ "source", "popup" ]
  static values = { localizedCopied: String, copyMessage: String, copySuccessMessage: String }

  copy() {
    navigator.clipboard.writeText(this.sourceTarget.textContent.trim())
    showToast(this.localizedCopiedValue)
  }

  copyCustomer() {
    navigator.clipboard.writeText(this.sourceTarget.textContent.trim())
    this.popupTarget.innerText= this.copySuccessMessageValue
  }

  mouseOver() {
    this.popupTarget.classList.add("show")
  }

  mouseOut() {
    this.popupTarget.classList.remove("show")
    this.popupTarget.innerText= this.copyMessageValue
  }
}
