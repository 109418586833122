import { Controller } from "@hotwired/stimulus"
import { meta } from "../helpers/meta"

export default class extends Controller {
  dispatch(eventName, { target = this.element, detail = {}, bubbles = true, cancelable = true } = {}) {
    const event = new CustomEvent(eventName, { detail, bubbles, cancelable })
    target.dispatchEvent(event)
    return event
  }

  meta(name) {
    return meta(name)
  }
}
