export default class {

  static formatCurrency(locale, currency, floatValue) {
    if (window.Intl) {
      return Intl.NumberFormat(locale, { style: 'currency', currency: currency}).format(floatValue);
    } else {
      return this.asNumber(floatValue);
    }
  }

  static asNumber(floatValue) {
    return floatValue.toFixed(2);
  }
}
