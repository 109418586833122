import NestedForm from "stimulus-rails-nested-form"

// Connects to data-controller="offer-indicative-table-service-nested-form"
export default class extends NestedForm {
  static targets = ['count']
  add(e) {
    e.preventDefault();
    let updateValue = parseInt(this.countTarget.getAttribute('value')) + 1
    this.countTarget.setAttribute("value", updateValue);
    const content = this.templateTarget.innerHTML
      .replace(/TABLE_SERVICE_DATA/g, new Date().getTime().toString())
      .replace(/POSITION_OF_INDEX_DATA/g, updateValue)

    this.targetTarget.insertAdjacentHTML("beforebegin", content);
  }

  remove(e) {
    super.remove(e);
    this.element.dispatchEvent(new CustomEvent('nested-form:removed', { bubbles: true }));
  }
}

