import ApplicationController from "./application_controller";
import CSVBoxImporter from "@csvbox/csvboxjs";
import MicroModal from 'micromodal';

// Connects to data-controller="csvbox-import-controller"
export default class extends ApplicationController {
  static targets = ['sheetId', 'prepareImportPath', 'importPath', 'userId', 'update']
  static values = { sheetId: String, prepareImportPath: String, importPath: String, userId: Number }

  importer = null;
  importData = null;

  initialize() {
    this.loadCSVBox();
  }

  // import data
  loadCSVBox() {
    this.importer = new CSVBoxImporter(this.sheetIdValue,{}, this.callback.bind(this), {});
    this.importer.setUpImporter();
    this.importer.setUser({
      user_id: this.userIdValue
    });
  }

  openImportModal() {
    this.importer.openModal();
  }

  callback(result, data) {
    let csrfToken = document.querySelector('meta[name="csrf-token"]').getAttribute('content');

    if (result) {
      this.importData = data;

      // prepare import
      try {
        fetch(this.prepareImportPathValue, {
          method: 'post',
          body: JSON.stringify(data),
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'X-Transaction': 'POST Example',
            'X-CSRF-Token': csrfToken
          }
        }).then((response) => {
          return response.json()
        }).then((res) => {
          if (res.status === 200) {
            this.handleResponseHtml(res.html, 'confirm')
          } else {
            this.handleResponseHtml(res.html, 'error')
          }
        }).catch((error) => {
          this.handleResponseHtml(error, 'error')
        })
      } catch (error) {
        this.handleResponseHtml(error, 'error')
      }
    } else {
      this.handleResponseHtml("There was some problem uploading the sheet", 'error')
    }
  }

  startImport() {
    let csrfToken = document.querySelector('meta[name="csrf-token"]').getAttribute('content');

    // Submit whether to update existing records or not
    this.importData.update = this.hasUpdateTarget ? this.updateTarget.checked : false

    try {
      fetch(this.importPathValue, {
        method: 'post',
        body: JSON.stringify(this.importData),
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'X-Transaction': 'POST Example',
          'X-CSRF-Token': csrfToken
        }
      }).then((response) => {
        return response.json()
      }).then((res) => {
        if (res.status === 200) {
          this.handleResponseHtml(res.html, 'result')
        } else {
          this.handleResponseHtml(res.html, 'error')
        }
      }).catch((error) => {
        this.handleResponseHtml(error, 'error')
      })
    } catch (error) {
      this.handleResponseHtml(error, 'error')
    }
  }

  closeResultModal() {
    MicroModal.close('import-result');
    window.location.reload()
  }

  handleResponseHtml(html, type) {
    let id = 'import-' + type + '-content';
    document.getElementById(id).innerHTML = html;
    MicroModal.show('import-' + type);
  }
}
