export class CancelRequestSelectedServices extends HTMLElement {
  addService(id, template) {
    template = template.replace(/INDEX/g, this.nextIndex)
    let serviceDetails = this.createServiceDetails(id)
    serviceDetails.innerHTML = template

    this.appendChild(serviceDetails)
  }

  removeService(id) {
    this.querySelector(`cancel-request-service-details[data-service-id="${id}"]`).remove()
  }

  get selectedServiceIds() {
    return Array.from(this.allSelectedServiceDetails).map(service => {
      return service.dataset.serviceId
    })
  }

  get size() {
    return this.allSelectedServiceDetails.length
  }

  // Private

  get nextIndex() {
    return new Date().valueOf().toString()
  }

  get allSelectedServiceDetails() {
    return this.querySelectorAll("cancel-request-service-details")
  }

  createServiceDetails(id) {
    let element = document.createElement("cancel-request-service-details")
    element.dataset.serviceId = id

    return element
  }
}
