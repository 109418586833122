import ApplicationController from "./application_controller";
export default class extends ApplicationController {
  static targets = [ 'container', 'input' ]

  connect() {
    if (this.data.has('selector')) {
      const dropZone = this.containerTarget
        .querySelector(this.data.get('selector'));

      if (!dropZone) return

      dropZone.addEventListener('drop', this.handleDroppedFile.bind(this));
      dropZone.addEventListener('dragover', this.preventDrag);
    }
  }

  preventDrag(event) {
    event.preventDefault();
  }

  handleDroppedFile(event) {
    event.preventDefault();

    this.inputTarget.files = event.dataTransfer.files;
    this.inputTarget.dispatchEvent(new Event('change'));
  }
}
