export class SearchResult extends HTMLElement {

  connectedCallback() {
    this.serviceId = this.dataset.serviceId
    this.serviceSelectedId = this.dataset.serviceSelectedId
  }

  get isSelected() {
    return this.classList.contains("selected")
  }

  get selectButton() {
    return this.querySelector("[data-select-text]")
  }

  get formInputs() {
    return this.querySelector("template").innerHTML
  }

  toggleSelection() {
    if (this.isSelected) {
      this.deselect()
    } else {
      this.select()
    }
  }

  select() {
    this.classList.add("selected")
    this.selectButton.textContent = this.selectButton.dataset.deselectText
  }

  deselect() {
    this.classList.remove("selected")
    this.selectButton.textContent = this.selectButton.dataset.selectText
  }
}