import ApplicationController from "./application_controller";
import SlimSelect from "slim-select";

export default class extends ApplicationController {
  static targets = [ 'select' ];

  connect() {
    this.parameterChanged()
  }

  hideAllParameters() {
    const allParameters = this.element.querySelectorAll(`[data-parameter-id]`);
    Array.from(allParameters).forEach(node => node.style.display = 'none');

    let allInputs = this.element.querySelectorAll('div[data-parameter-id] input, div[data-parameter-id] select');
    allInputs.forEach((input) => {
      if(input.dataset.controller?.includes('slim-select-multiple') && input.slim) {
        input.slim.disable();
      } else {
        input.disabled = true
      }
    })
  }

  parameterChanged() {
    this.hideAllParameters();
    this.showFieldsForParameter(this.selectedParameter);
  }

  showFieldsForParameter(parameter) {
    if (typeof(parameter) == undefined) return;

    const fieldWrapper = this.element.querySelector(`[data-parameter-id="${parameter}"]`);
    if (fieldWrapper) {
      fieldWrapper.style.display = 'grid';

      let inputFields = fieldWrapper.querySelectorAll('input, select');
      inputFields.forEach(inputField => {
        if(inputField.dataset.controller?.includes('slim-select-multiple') && inputField.slim) {
          inputField.slim.enable();
        } else {
          inputField.removeAttribute('disabled')
        }
      })
    }
  }

  get selectedParameter() {
    return this.selectTarget.value;
  }
}

