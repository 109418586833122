import ApplicationController from "./application_controller";
import PasswordHelper from "../helpers/password_helper";
import InputErrorsHelper from "../helpers/input_errors_helper";
import { get } from "@rails/request.js";

// Connects to data-controller="onboarding-user-setup"
export default class extends ApplicationController {

  async email_exists_check(event) {
    let email = event.target.value;

    if(email.length > 0) {
      let response = await get(`/users/onboarding/users_details/exists?email=${email}`, {})
      const body = await response.json

      if(body.exists) {
        document.getElementsByClassName('hided-fields')[0].classList.add('hidden')
        document.getElementsByClassName('existing-user-hint')[0].classList.remove('hidden')
        document.getElementsByClassName('email-field')[0].classList.add('warning-email-field')
        document.getElementsByClassName('reset-password-form')[0].style.display = 'block'
        document.getElementsByClassName('submit-button')[0].style.display = 'none'
        document.getElementById('hidden_user_email').value = document.getElementsByClassName('email-field')[0].value
      } else {
        document.getElementsByClassName('hided-fields')[0].classList.remove('hidden')
        document.getElementsByClassName('existing-user-hint')[0].classList.add('hidden')
        document.getElementsByClassName('email-field')[0].classList.remove('warning-email-field')
        document.getElementsByClassName('reset-password-form')[0].style.display = 'none'
        document.getElementsByClassName('submit-button')[0].style.display = 'block'
      }
    }
  }

  updateViewByPassword(event) {
    PasswordHelper.hideAll(event.target.value)
    PasswordHelper.showLevel(event.target.value)
  }
}
