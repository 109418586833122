import swal from 'sweetalert';
import { meta } from "../helpers/meta"

export function confirm(message, formElement) {
  let title = meta("confirm:title")
  let cancelButton = meta("confirm:button:cancel")
  let okButton = meta("confirm:button:ok");

  if (formElement.querySelector('button')) {
    let button = formElement.querySelector('button')
    if(button.dataset.cancel) cancelButton = button.dataset.cancel 
    if(button.dataset.ok) okButton = button.dataset.ok
    if(button.dataset.title) title = button.dataset.title
  }

  swal({
    title: title,
    text: message,
    icon: 'warning',
    buttons: [cancelButton, okButton],
    className: 'vl-confirmation'
  }).then((result) => {
    if (result) {
      formElement.removeAttribute("data-turbo-confirm")
      formElement.requestSubmit()
    }
  })
}
