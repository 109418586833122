import ApplicationController from "./application_controller";

export default class extends ApplicationController {
  static targets = ["categoryLabel"]

  subcategorySelected(event) {
    let selectControl = event.target
    let value = selectControl.value
    let option = selectControl.querySelector(`option[value="${value}"]`)
    let categoryName = option.closest("optgroup").getAttribute("label")

    this.categoryLabelTarget.textContent = categoryName
    this.categoryLabelTarget.classList.remove("tag--disabled")
  }
}
