import Application_controller from "./application_controller";

// Connects to data-controller="offer-indicative-integrator-checkbox"
export default class extends Application_controller {
  static targets = ['checkbox']
  connect() {}

  select(target) {
    target.classList.add("selected")
    target.textContent = target.dataset.deselectText
    target.closest('.l-container').classList.add('selected')
  }

  deselect(target) {
    target.classList.remove("selected")
    target.textContent = target.dataset.selectText
    target.closest('.l-container').classList.remove('selected')

  }

  changeDataAction(e) {
    let offers_indicative_table = document.getElementById("offers_indicative_table_id")
    let button = e.target;
    let checkStatus = e.target.getAttribute("value")
    if (checkStatus == 'false') {
      if (this.hasCheckboxTarget) {
        this.checkboxTargets.forEach((item) => {
          if (item == button) {
            this.select(button)
            button.value = true;
            offers_indicative_table.value = button.getAttribute('name')
          }
          else {
            this.deselect(item)
            item.value = false;
          }
        })
      }
    } else {
      this.deselect(button)
      button.value = false;
      offers_indicative_table.value = ''
    }

    // if (this.hasCheckboxTarget) {
    //   this.checkboxTargets.forEach((item) => {
    //     if(item == e.target) {
    //       console.log(e.target.getAttribute('value'))
    //       item.checked
    //         ? offers_indicative_table.value = e.target.getAttribute('value')
    //         : offers_indicative_table.value = ""
    //     }
    //     else {
    //       item.checked = false
    //     }
    //   })
    // }
  }
}
