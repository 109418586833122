
// Connects to data-controller="toggle-view-more"
import ApplicationController from "./application_controller";

export default class extends ApplicationController {
  static targets = ['boxContent', 'btnMore']

  connect() {
    this.boxContentTargets.forEach(element => {
      var lineHeight = parseFloat(window.getComputedStyle(element).lineHeight);
      var totalHeight = element.querySelector('p').clientHeight
      var numLines = Math.round(totalHeight / lineHeight);
      if (numLines > 9) {
        element.querySelector('p').classList.add("custom_view_more")
        element.querySelector('li').classList.remove("hidden")
      }
    })
  }

  isShowFull(e) {
    var content = e.target.closest('li').parentElement.querySelector('p')
    var isShowFull = content.classList.contains("custom_view_more")

    if (isShowFull) {
      e.target.textContent = 'Hidden ...'
      content.classList.remove("custom_view_more")
    } else {
      e.target.textContent = 'More ...'
      content.classList.add("custom_view_more")
    }
  }
}
