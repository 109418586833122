import ApplicationController from "./application_controller";
export default class extends ApplicationController {
  static targets = ["checkbox", "policyModal"]

  show(event) {
    if (!this.policyShown || event.currentTarget.tagName == "A") {
      MicroModal.show(this.policyModalTarget.id)
      this.checkboxTarget.checked = false
    }

    this.policyShown = true
  }

  removeUncheckedError(event) {
    this.show(event)

    let input = event.target

    if(!input.checked) {
      input.nextElementSibling.classList.remove('required__checkbox__sub')
    } else {
      input.nextElementSibling.classList.remove('required__checkbox__sub')
    }
  }


  accept() {
    this.checkboxTarget.checked = true
  }
}
