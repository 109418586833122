import ApplicationController from "./application_controller";
import { meta } from "../helpers/meta";

export default class extends ApplicationController {
  static values = { message: String }

  confirm(event) {
    if (event.overrideConfirmation) { return true }

    event.preventDefault()

    swal({
      text: this.messageValue,
      buttons: [meta("confirm:button:cancel"), meta("confirm:button:ok")]
    })
    .then((result) => {
      if (result) {
        let newEvent = new MouseEvent('click', {
          bubbles: true,
          cancelable: true
        });

        newEvent.overrideConfirmation = true
        this.element.dispatchEvent(newEvent)
      }
    })
  }
}

