import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="update-attr-estimate-value"
export default class extends Controller {
  connect() {

  }

  changeValue() {
    let tender_estimated_amount = document.getElementById("tender_estimated_amount")
    let data = tender_estimated_amount.value
    let changeData = document.getElementById("Estimated_amount")
    if(changeData){
      changeData.value = data
    }
    let tender_estimate_amount_tender = document.getElementById("tender_estimate_amount_tender")
    if(tender_estimate_amount_tender) {
      tender_estimate_amount_tender.value = data
    }

    let change = document.getElementById("estimated_color")
    if (change) {
      if(!change.classList.contains('button-negative')) change.classList.add('button-negative')
    }
  }
}
