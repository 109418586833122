
export default class {
  static showCantBeBlankError(prefix, requiredFields) {
    let showError = false;
    let lastEmptyField;

    for(const element of requiredFields) {
      if(document.getElementById(`${prefix}${element}`) !== null) {
        if(document.getElementById(`${prefix}${element}`).value.length <= 0) {
          if (document.getElementsByClassName(`${element}_blank_error`).length > 0) {
            document.getElementsByClassName(`${element}_blank_error`)[0].remove()
          }
          if(lastEmptyField === undefined) {
            lastEmptyField = element;
          }
          let input = document.getElementById(`${prefix}${element}`)
          const message = document.createElement('p');
          message.classList.add(`${element}_blank_error`)
          message.classList.add('field_error')
          message.textContent = "This field is required"
          if(element === 'device_manufacturer' || element === 'device_model') {
            message.classList.add('mt-3')
            input.parentNode.insertBefore(message, input.parentNode.nextSibling);
          } else {
            input.parentNode.insertBefore(message, input.nextSibling);
          }
          input.classList.add('onboarding-required-input')
          showError = true;
        } else {
          if (document.getElementsByClassName(`${element}_blank_error`).length > 0) {
            document.getElementsByClassName(`${element}_blank_error`)[0].remove()
          }
          document.getElementById(`${prefix}${element}`).classList.remove('onboarding-required-input')
        }
      } else {
        console.log(`${prefix}${element}`)
      }
    }

    const lastFieldElement = document.getElementById(`${prefix}${lastEmptyField}`);
    if (lastFieldElement) {
      lastFieldElement.focus();
      lastFieldElement.scrollIntoView({ behavior: 'smooth' });
    }

    return showError;
  }

  static showCantBeUncheckedError(prefix, requiredFields) {
    let showError = false;

    for(const element of requiredFields) {
      if(document.getElementById(`${prefix}${element}`) === null) {
        continue
      }

      if(!document.getElementById(`${prefix}${element}`).checked) {
        let input = document.getElementById(`${prefix}${element}`)
        input.nextElementSibling.classList.add('required__checkbox__sub')
        showError = true;
      } else {
        document.getElementById(`${prefix}${element}`).nextElementSibling.classList.remove('required__checkbox__sub')
      }
    }

    return showError;
  }

  static showMinCharactersError(prefix, minCharNumber, requiredFields) {
    let showError = false;

    for(const element of requiredFields) {
      if(!document.getElementsByClassName(`${element}_blank_error`).length) {
        if(document.getElementById(`${prefix}${element}`).value.length < minCharNumber) {
          if (document.getElementsByClassName(`${element}_error`).length > 0) {
            document.getElementsByClassName(`${element}_error`)[0].remove()
          }
          let input = document.getElementById(`${prefix}${element}`)
          const message = document.createElement('p');
          message.classList.add(`${element}_error`)
          message.classList.add('field_error')
          message.textContent = `is too short (minimum is ${minCharNumber} characters)`
          input.parentNode.insertBefore(message, input.nextSibling);
          input.classList.add('onboarding-required-input')
          showError = true;
        } else {
          if (document.getElementsByClassName(`${element}_error`).length > 0) {
            document.getElementsByClassName(`${element}_error`)[0].remove()
          }
          document.getElementById(`${prefix}${element}`).classList.remove('onboarding-required-input')
        }
      }
    }
    return showError;
  }
}
