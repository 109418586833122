import ApplicationController from "./application_controller";

/**
 * Toggleable advances sections
 *
 * On the toggle() action, hides elements in toggledTargets
 * Checkboxes on togglerTargets can actuate toggle()
 * If any of its fieldTargets has a value, it default to visible on connect()
 */
// Connects to data-controller="advanced-section"
export default class extends ApplicationController {
  static targets = ["field", "toggled", "toggler", "minus", "plus", "advanceOptions"];
  static values = { visible: Boolean }; // stimulus default is false

  toggle() {
    this.visibleValue = !this.visibleValue;
    this.chevontoggle(this.visibleValue)
  }

  chevontoggle(status) {
    if(status == true) {
      this.plusTarget.style.display = "none"
      this.minusTarget.style.display = "inline-block"
    } else {
      this.plusTarget.style.display = "inline-block"
      this.minusTarget.style.display = "none"
    }
  }

  toggleHandler(event) {
    this.visibleValue = Boolean(event.target.checked);
  }

  connect() {
    // connect to toggler checkboxes
    this.togglerTargets.forEach(element => {
      element.addEventListener('change', this.toggleHandler.bind(this));
    });
    // set panel visible if any of the fields is not blank:
    this.visibleValue = this.fieldTargets.some(element => Boolean(element.value));

    // set visible default true
    this.advanceOptionsTarget.setAttribute('data-toggle-advanced-visible-value', true)
  }

  visibleValueChanged() {
    // hide/unhide elements:
    this.toggledTargets.forEach(target => {
      target.classList.toggle("dropdown-close", !this.visibleValue);
    });
    // disable all fields:
    this.fieldTargets.forEach(target => {
      target.disabled = !this.visibleValue;
    });
    // tick all "advanced" toggles/checkboxed - if set up
    this.togglerTargets.forEach(target => { target.checked = this.visibleValue });
  }
}
