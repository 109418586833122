import Sortable from '@stimulus-components/sortable'

// Connects to data-controller="sortable"
export default class extends Sortable {
  static targets = ['dragItem', 'positionSort']
  connect() {
    super.connect();
  }

  end(evt) {
    for(let i = 0; i < this.positionSortTargets.length; i++ ) {
      this.positionSortTargets[i].setAttribute('value', i)
    }
  }

  get defaultOptions() {
    return {
      forceFallback: true,
      removeCloneOnHide: true,
    }
  }
}
