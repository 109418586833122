import CurrencyHelper from "../helpers/currency_helper"
import ApplicationController from "./application_controller";

export default class extends ApplicationController {
  static targets = [ 'quantity', 'number', 'total' ]

  connect() {
    this.updateTotal();
  }

  updateTotal() {
    if (this.hasQuantityTarget && this.hasNumberTarget && this.hasTotalTarget) {
      const quantity = parseFloat(this.quantityTarget.value) || 0;
      const number = parseFloat(this.numberTarget.value) || 0;

      const total = number * quantity;
      this.totalTarget.textContent = CurrencyHelper.asNumber(total);
      this.totalTarget.dispatchEvent(new CustomEvent('change'))
    }
  }
}
