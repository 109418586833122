import Chart from 'chart.js/auto';

export const getValue = (totalValue) => {
  return Number.isInteger(totalValue) ? totalValue : Number((totalValue).toFixed(2))
}

export const getChart = ({ ctx, labels, label, value, unit, dataMap }) => {
  new Chart(ctx, {
    type: 'line',
    data: {
      labels: labels,
      datasets: [{
        label: `${label.toUpperCase().replace(/_/g, " ")} total: ${value}${unit}`,
        data: dataMap,
        borderWidth: 1,
        backgroundColor: "#DCE9BB",
        borderColor: "#86A044",
        tension: 0.4,
        fill: {
          target: 'origin',
          above: '#DCE9BB'
        }
      }]
    },
    options: {
      scales: {
        y: {
          suggestedMin: 5,
          suggestedMax: 10,
          beginAtZero: true,
          ticks: {
            font: {
              size: 6
            }
          }
        },
        x: {
          ticks: {
            font: {
              size: 6
            }
          }
        }
      },
      plugins: {
        legend: {
          labels: {
            font: {
              size: 8
            }
          }
        }
      }
    }
  })
}

export const getChartStatusAndDestroy = (id) => {
  const chartStatus = Chart.getChart(id)
  if (chartStatus != undefined) {
    chartStatus.destroy();
  }
}

export const getData = (data) => {
  return {
    label: Object.keys(data)[0],
    totalValue: Object.values(data)[1],
    id: Object.values(data)[2],
    unit: Object.values(data)[3],
    dataMap: Object.values(data)[0].map(key => Object.values(key)[0]),
    labels: Object.values(data)[0].map(key => Object.keys(key)[0])
  }
}
