import ApplicationController from "./application_controller";

export default class extends ApplicationController {
  static values = {
    form: String,
  }
  handler;
  connect() {
    let { element, printElement } = this

    this.handler = (event) => {
      if((event.ctrlKey || event.metaKey) && event.keyCode == 80) {
        event.preventDefault();
        var detailsElement = document.querySelector("details");
        detailsElement.setAttribute("open", "open");
        printElement(document.getElementById(`${element.id}`));
      }
    }

    document.addEventListener("keydown", this.handler);
  }

  printForm(e) {
    let detailsElement = document.querySelector("details");
    let wasHidden = false;
    if(detailsElement.classList.contains('hidden')) {
      detailsElement.classList.remove('hidden');
      wasHidden = true;
    }
    detailsElement.setAttribute("open", "open");
    this.printElement(document.getElementById(`${this.formValue}`));
    if(wasHidden) {
      detailsElement.classList.add('hidden');
      wasHidden = false;
    }
  }

  printElement(element) {
    document.body.innerHTML = element.parentElement.innerHTML;
    document.body.classList.add('print_mode')
    let is_chrome = Boolean(window.chrome)
    if (is_chrome) {
      window.onload = () => {
        setTimeout(function () {
          window.focus();
          window.print();
       }, 100);
      };
      window.onload();
    } else {
      window.print();
    }
  }

  disconnect() {
    document.removeEventListener("keydown", this.handler);
  }
}
