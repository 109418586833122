import {get} from "@rails/request.js";
import ApplicationController from "./application_controller";

// Connects to data-controller="calibration-measurement"
export default class extends ApplicationController {
  static targets = ["dataSet", "selectorSet", "dataResolutionUnitSaved", "deviceNameOrType", "manufacturer", "deviceName"];

  async connect() {
    let selector = this.selectorSetTarget.id;
    let select = document.getElementById(selector).options;
    let target = this.dataSetTarget.id;
    let unitsave = this.dataResolutionUnitSavedTarget.getAttribute('value');
    if (unitsave) {
      await get(`/calibrations/update_value?unit_save=${unitsave}&calibration_target=${target}&select_model=${select[select.selectedIndex].value}`, {
        responseKind: "turbo-stream"
      })
    }
  }

  async change_message_category(event) {
    let select = event.target.options;
    let target = this.dataSetTarget.id;
    let inputTarget = this.deviceNameTarget
    let { json } = await get(`/calibrations/device_placeholder?&select_model=${select[select.selectedIndex].value}`, {})
    let body = await json
    if (body.placeholder) {
      inputTarget.placeholder = body.placeholder
    }
    await get(`/calibrations/update_value?calibration_target=${target}&select_model=${select[select.selectedIndex].value}`, {
      responseKind: "turbo-stream"
    })
  }

  changeManufacturer(e) {
    this.manufacturerTarget.innerText = e.target.value ? `\u00A0from \"${e.target.value}\"` : ''
  }

  changeDeviceNameOrType(e) {
    this.deviceNameOrTypeTarget.innerText = e.target.value ? `\u00A0\"${e.target.value}\"` : ''
  }
}
