import { Controller } from "@hotwired/stimulus"
import mm from 'moment-timezone'

function getTimeZoneLongName(date, locale, timeZone) {
  const f = Intl.DateTimeFormat(locale, { timeZone, timeZoneName: 'long' });
  return f.formatToParts(date).find(x => x.type === 'timeZoneName').value;
}
// Connects to data-controller="get-client-time-zone"
export default class extends Controller {
  connect() {
    let value = mm().tz(Intl.DateTimeFormat().resolvedOptions().timeZone).format("Z");
    let current_zone = document.getElementById('current_zone')
    current_zone.value = value

    let current_zone_name = document.getElementById('current_zone_name')
    current_zone_name.value = "(" + mm().tz(Intl.DateTimeFormat().resolvedOptions().timeZone).format("z") + " - " +
    getTimeZoneLongName(new Date(), "en-us", Intl.DateTimeFormat().resolvedOptions().timeZone) + ")"
  }
}
