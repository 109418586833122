import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="nias-fb-pet-request"
export default class extends Controller {
  static targets = [
    // calculator
    "abaMicrons",
    "hiddenAbaMicrons",
    "totalPcr",
    "hiddenTotalPcr",
    // input fields
    "totalAbaThickness",
    "layerBThickness",
    "layerBPcr",
    // sample date fields
    "inputSampleDate",
    "outputSampleDate"
  ]

  totalAbaThicknessTargetConnected(element) {
    this.totalAbaThickness = element.value;
  }

  layerBThicknessTargetConnected(element) {
    this.layerBThickness = element.value;
  }

  reset(event) {
    this.totalAbaThicknessTarget.value = '';
    this.layerBThicknessTarget.value = '';
    this.layerBPcrTarget.value = '';
    this.calculate();
  }

  layerBPcrTargetConnected(element) {
    this.layerBPcr = element.value;
  }

  inputTotalAbaThickness(event) {
    this.totalAbaThickness = Number(event.currentTarget.value);
    if(this.totalAbaThickness < 1) {
      this.totalAbaThickness = 1
    }
    this.calculate();
  }

  inputLayerBThickness(event) {
    this.layerBThickness = Number(event.currentTarget.value);
    this.calculate();
  }

  inputLayerBPcr(event) {
    this.layerBPcr = Number(event.currentTarget.value);
    this.calculate();
  }

  // cf. submission-controller
  // When the blur event is triggered (last event, triggered by date-picker),
  // the date is not set yet. We should wait till it's ready to read and write.
  shareSampleDate() {
    setTimeout(() => {
      this.outputSampleDateTarget.value = this.inputSampleDateTarget.value;
    }, 200);
  }

  calculate() {
    if (this.totalAbaThickness > 0 && this.layerBThickness > 0 && this.layerBPcr > 0) {
      let aThickness = (this.totalAbaThickness - this.layerBThickness) / 2;
      let aPcrRatio = 0.0; // all virgin
      let totalPcr = (this.layerBThickness / this.totalAbaThickness) * this.layerBPcr + (1.0 - this.layerBThickness / this.totalAbaThickness) * aPcrRatio;

      let abaMicrons = this.abaMicronsTemplate(aThickness, this.layerBThickness);
      let roundedTotalPcr = Math.round(totalPcr * 100) / 100

      this.abaMicronsTarget.innerText = abaMicrons;
      this.hiddenAbaMicronsTarget.value = abaMicrons;

      this.totalPcrTarget.innerText = this.totalPcrTemplate(roundedTotalPcr);
      this.hiddenTotalPcrTargets.forEach((node) => { node.value = roundedTotalPcr });
    } else {
      this.abaMicronsTarget.innerText = this.abaMicronsTemplate();
      this.totalPcrTarget.innerText = this.totalPcrTemplate();
    }
  }

  abaMicronsTemplate(aThickness = 0, bThickness = 0) {
    return `${aThickness}μm / ${bThickness}μm / ${aThickness}μm`;
  }

  totalPcrTemplate(totalPcr = 0) {
    return `${totalPcr}%`;
  }
}
