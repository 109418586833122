import NestedForm from "stimulus-rails-nested-form"

export default class extends NestedForm {
  add(e) {
    super.add(e);
    setTimeout(() => {
      e.target.dispatchEvent(new CustomEvent('nested-form:added'));
    }, 500)
  }

  remove(e) {
    this.element.dispatchEvent(new CustomEvent('nested-form:removed', { bubbles: true }));
    super.remove(e);
  }
}

