import ApplicationController from "./application_controller";
export default class extends ApplicationController {
  static targets = [ "listContainer", "list", "input" ]

  displaySelectedFiles() {
    const files = this.inputTarget.files;
    var ul = document.createElement('ul');

    for (var i = 0; i < files.length; i++) {
      const file = files[i];
      var li = document.createElement('li');

      var progressContainer = document.createElement('div');
      progressContainer.classList.add('direct-upload__progress');
      progressContainer.style.width = '0%';

      var span = document.createElement('span')
      span.classList.add('direct-upload__filename');
      span.textContent = file.name;

      li.classList.add('direct-upload', 'direct-upload--pending');
      li.dataset.fileName = file.name;
      li.appendChild(progressContainer)
      li.appendChild(span)

      ul.appendChild(li);
    }

    this.listTarget.firstChild.replaceWith(ul);
    this.listContainerTarget.classList.remove("absolute", "left-[-9999px]");
  }

  reset(event) {
    event.preventDefault()
    this.inputTarget.value = null
    this.displaySelectedFiles()
  }

  delete(event) {
    event.target.closest("li").remove()
  }
}
