import { Controller } from "@hotwired/stimulus"
export default class extends Controller {
  static values = { name: Object }

  tableNameMouseOut() {
    const { name, number }  = this.nameValue
    const popup = document.getElementById(`myPopup${typeof(name) === 'object' ? name[0] : name}-${number}`)
    popup.classList.remove("show")
  }

  tableNameMouseOver = () => {
    const { name, number } = this.nameValue
    const popup = document.getElementById(
      `myPopup${typeof(name) === 'object' ? name[0] : name}-${number}`)
    popup.classList.add("show")
  }
}
