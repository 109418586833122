import NestedForm from "stimulus-rails-nested-form"

// Connects to data-controller="nias-nested-sample-overall-migration"
export default class extends NestedForm {
  static targets = ['migration', 'condition', 'migrant']

  connect() {
    this.element[this.identifier] = this
  }

  add(e, index = 0) {
    e.preventDefault();
    const content = this.templateTarget.innerHTML
        .replace(/NEW_OVERALL_MIGRATION/g, new Date().getTime().toString() + index * 200)

    this.targetTarget.insertAdjacentHTML("beforebegin", content);
  }

  get getTargetData() {
    return this.migrationTargets.map((item, index) => ({
      condition: this.conditionTargets[index].value,
      migrant: this.migrantTargets[index].value,
    }))
  }

  setTargetData(e, values) {
    this.migrationTargets.forEach((item) => item.remove())
    values.map((item, index) => {
      this.add(e, index)
      let targets = this.conditionTargets.length
      this.conditionTargets[targets - 1].value = item.condition
      this.migrantTargets[targets - 1].value = item.migrant
    })
  }

  remove_all() {
    this.migrationTargets.map((item) => {
      item.remove()
    })
  }
}
