import ApplicationController from "./application_controller";

export default class extends ApplicationController {
  static targets = ["trigger"];

  connect() {
    super.connect();

    let save_button = document.getElementById('saveButton');
    let checkboxes = document.querySelectorAll("input[type='checkbox'][name='activate']");

    checkboxes.forEach(function(checkbox) {
      checkbox.addEventListener("change", function() {
        const hiddenField = this.parentElement.querySelector("input[type='hidden']");
        const base_id = hiddenField.id.slice(0, -11)
        const disabled = this.checked

        hiddenField.value = disabled ? "true" : "false";
        const price_input = document.getElementById(`${base_id}__base_price`)
        if (price_input) {
          price_input.disabled = !disabled;
        }
        const tat_input = document.getElementById(`${base_id}__base_tat`)
        if (tat_input) {
          tat_input.disabled = !disabled;
        }

        save_button.disabled = false;
      });
    });
  }

  triggerTargetConnected(element) {
    element.addEventListener('change', () => {
      let save_button = document.getElementById('saveButton');
      save_button.disabled = false;
    });
  }

  submitPriceListForm(e) {
    e.preventDefault();
    const price_item_types = document.querySelectorAll('.price_item_type');

    price_item_types.forEach(price_item_type => {
      price_item_type.disabled = false;
    });

    let form = document.getElementById('price_list_form')
    form.requestSubmit()
  }
}
