import { Controller } from "@hotwired/stimulus"
import ApplicationController from "./application_controller";

// Connects to data-controller="direct-tab"
export default class extends ApplicationController {
  static targets = ['request', 'requestTab', 'offer', 'offerTab', 'indicative', 'indicativeTab']

  connect() {
    super.connect();
  }

  showTables(event) {
    const value = event.target.dataset.value
    const targets = ['request', 'offer', 'indicative']
    targets.forEach(e => {
      if (e === value) {
        this[`${e}TabTarget`].classList.remove('passive-tab')
        this[`${value}TabTarget`].classList.add('active-tab')
        this[`${value}Target`].classList.remove('hidden')
      } else {
        this[`${e}TabTarget`].classList.remove('active-tab')
        this[`${e}TabTarget`].classList.add('passive-tab')
        this[`${e}Target`].classList.add('hidden')
      }
    })
  }
}
