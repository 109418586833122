import NestedForm from "stimulus-rails-nested-form"

// Connects to data-controller="nias-nested-sample-specific-migration"
export default class extends NestedForm {
  static targets = ['migration', 'durationM', 'temperatureC', 'analysis', 'simulant']

  connect() {
    this.element[this.identifier] = this
  }

  add(e, index = 0) {
    e.preventDefault();
    const content = this.templateTarget.innerHTML
        .replace(/NEW_SPECIFIC_MIGRATION/g, new Date().getTime().toString() + index * 200)

    this.targetTarget.insertAdjacentHTML("beforebegin", content);
  }

  get getTargetData() {
    return this.migrationTargets.map((item, index) => ({
      durationM: this.durationMTargets[index].value,
      temperatureC: this.temperatureCTargets[index].value,
      analysis: this.analysisTargets[index].value,
      simulant: this.simulantTargets[index].value
    }))
  }

  setTargetData(e, values) {
    this.migrationTargets.forEach((item) => item.remove())
    values.map((item, index) => {
      this.add(e, index)
      let targets = this.durationMTargets.length
      this.durationMTargets[targets - 1].value = item.durationM
      this.temperatureCTargets[targets - 1].value = item.temperatureC
      this.analysisTargets[targets - 1].value = item.analysis
      this.simulantTargets[targets - 1].value = item.simulant
    })
  }
  remove_all() {
    this.migrationTargets.map((item) => {
      item.remove()
    })
  }
}
