import ApplicationController from "./application_controller";

export default class extends ApplicationController {
  connect() {
    const trixEditor = this.element.querySelector('.trix-content')
    const addButton = this.element.querySelector('#project-add')

    if(trixEditor !== null) {
      if (trixEditor.offsetWidth < trixEditor.scrollWidth || trixEditor.offsetHeight < trixEditor.scrollHeight) {
        addButton.classList.remove("absolute", "left-[-9999px]")
      }
    }
  }

  moreTarget() { return this.element.querySelector('#project-add') }

  showMore(e) {
    this.moreTarget().classList.add("absolute", "left-[-9999px]")
    this.element.classList.remove('hidden')
  }
}
