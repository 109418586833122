import ApplicationController from "./application_controller";

export default class extends ApplicationController {
  static targets = ['parent', 'child', 'source'];

  connect() {
    this.sourceTarget.style.display = 'none';
    this.setSelectableChildren()
  }

  parentChanged() {
    this.setSelectableChildren();
  }

  setSelectableChildren() {
    const optionGroup = this.sourceTarget.querySelector(`optgroup[label="${this.parentText}"]`);
    if (optionGroup == null) return;

    const options = optionGroup.childNodes;

    this.childTargets.forEach((childTarget) => {
      childTarget.innerHTML = "";
      options.forEach(opt => childTarget.append(opt.cloneNode(true)));
    })
  }

  get parentText() {
    const parentId = this.parentTarget.selectedIndex;
    const parentText = this.parentTarget.options[parentId].text;

    return parentText;
  }
}
