import ApplicationController from "./application_controller";

// Add this controller to any <turbo-frame>, and will disable all <input>,
// <button> and <textarea> while turbo is in a loading state
export default class extends ApplicationController {
  mutationObserver = new MutationObserver(this.onAttributeChange.bind(this));
  disabledElements = [];

  connect() {
    this.mutationObserver.observe(this.element, { attributes: true })
  }

  disconnect() {
    this.mutationObserver.disconnect();
  }

  onAttributeChange(mutations) {
    let isBusy = mutations.find((item) => item.attributeName === 'busy');
    if (isBusy) {
      // do not re-disable if we have already disabled things:
      if (this.disabledElements.length > 0) {
        return;
      }
      // select clickable / updatable elements, and save them for re-enable:
      this.disabledElements = Array.from(this.element.querySelectorAll('button, input, textarea'))
        .filter((element) => element.getAttribute('disabled') == null);
      // disable all of them:
      this.disabledElements.forEach((element) => {
        element.setAttribute('disabled', 'true');
      });
    } else {
      // not busy, remove "disabled=true" from previously disabled nodes
      this.element.querySelectorAll('button, input, textarea').forEach((element) => {
        if (this.disabledElements.includes(element)) {
          element.removeAttribute('disabled');
        }
      });
      // cleanup:
      this.disabledElements = [];
    }
  }
}
