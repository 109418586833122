import NestedForm from "stimulus-rails-nested-form"

// Connects to data-controller="nias-pet-request"
export default class extends NestedForm {
  static targets = [
    'sample', 'numberSample',
    'samplingDate', 'reportFormat', 'shape',
    'collectionSystem', 'collectionCountryCode', 'decontaminationTechnology', 'rpetContent',
    'machineNumber', 'rinNumber', 'oligomer', 'nvocNtgt', 'nvocTgt',
    'svocNtgt', 'svocTgt', 'vocNtgt', 'vocTgt',
    'compositionAnalysisForm', 'overallMigrationForm', 'specificMigrationForm',
    'confirm', 'preselectedPricingBox', 'preselectedPricingId',
    'valueButton', 'valueText', 'selectTemplate'
  ]

  static values = {
    dataObject: String,
    flagRendered: Boolean
  }

  connect() {
    if(this.sampleTargets.length == 0) {
      const content = this.templateTarget.innerHTML.replace(/NEW_RECORD/g, new Date().getTime().toString());
      this.targetTarget.insertAdjacentHTML("beforebegin", content);
    }
    let formData = new FormData(this.element)
    formData.delete("nias_pet_request[pre_template]")
    this.dataObjectValue = JSON.stringify(Object.fromEntries(formData.entries()))
    this.setnumber()
  }

  add(e) {
    super.add(e);
    this.setnumber()
  }

  remove(e) {
    if (this.sampleTargets.length > 1) {
      super.remove(e);
    }
  }

  setnumber() {
    this.numberSampleTargets.map((item, index) => {
      item.innerText = `Sample ${this.convertNumber(index + 1)}`
    })
  }

  convertNumber(index) {
    if (index < 10) {
      return `0${index}`
    } else {
      return `${index}`
    }
  }

  duplicateSample(e) {
    this.add(e);
    const lengthItem = this.numberSampleTargets.length - 1

    // field_part
    this.samplingDateTargets[lengthItem].value = this.samplingDateTargets[lengthItem - 1].value
    this.reportFormatTargets[lengthItem].value = this.reportFormatTargets[lengthItem - 1].value
    this.shapeTargets[lengthItem].value = this.shapeTargets[lengthItem - 1].value
    this.collectionSystemTargets[lengthItem].value = this.collectionSystemTargets[lengthItem - 1].value
    this.collectionCountryCodeTargets[lengthItem].value = this.collectionCountryCodeTargets[lengthItem - 1].value
    this.decontaminationTechnologyTargets[lengthItem].value = this.decontaminationTechnologyTargets[lengthItem - 1].value
    this.rpetContentTargets[lengthItem].value = this.rpetContentTargets[lengthItem - 1].value
    this.machineNumberTargets[lengthItem].value = this.machineNumberTargets[lengthItem - 1].value
    this.rinNumberTargets[lengthItem].value = this.rinNumberTargets[lengthItem - 1].value

    setTimeout(() => {
      // Composition analysis
      const ca_get = this.compositionAnalysisFormTargets[lengthItem - 1]['nias-nested-sample-composition-analysis'].getTargetData
      this.compositionAnalysisFormTargets[lengthItem]['nias-nested-sample-composition-analysis'].setTargetData(e, ca_get)

      // SMT - Specific migration test
      const smt_get = this.specificMigrationFormTargets[lengthItem - 1]['nias-nested-sample-specific-migration'].getTargetData
      this.specificMigrationFormTargets[lengthItem]['nias-nested-sample-specific-migration'].setTargetData(e, smt_get)

      // OMT - Overall migration tests
      const omt_get = this.overallMigrationFormTargets[lengthItem - 1]['nias-nested-sample-overall-migration'].getTargetData
      this.overallMigrationFormTargets[lengthItem]['nias-nested-sample-overall-migration'].setTargetData(e, omt_get)
    })
  }

  switchTabs(e) {
    let formData = new FormData(this.element)
    formData.delete("nias_pet_request[pre_template]")
    if(!this.flagRenderedValue && this.dataObjectValue === JSON.stringify(Object.fromEntries(formData.entries()))) {
      this.confirmTarget.dispatchEvent(new Event("nias-pet-request:redirect"))
    } else {
      this.confirmTarget.dispatchEvent(new Event("nias-pet-request:check"))
    }
  }

  removePricingId() {
    this.preselectedPricingBoxTarget.classList.add('hidden')
    this.preselectedPricingIdTarget.value = ''
    this.valueButtonTarget.value = this.valueTextTarget.value
  }

  switchTemplate(e) {
    let formData = new FormData(this.element)
    formData.delete("nias_pet_request[pre_template]")
    if(this.dataObjectValue === JSON.stringify(Object.fromEntries(formData.entries()))) {
      this.selectTemplateTarget.dispatchEvent(new Event("nias-pet-request:filled"))
    } else {
      this.selectTemplateTarget.dispatchEvent(new Event("nias-pet-request:checkConfirm"))
    }
  }
}
