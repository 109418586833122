export class ElementObserver {
  constructor(element, selector, delegate) {
    this.element = element
    this.selector = selector
    this.mutationObserver = new MutationObserver((mutations) => this.processMutations(mutations))
    this.delegate = delegate
  }

  start() {
    this.mutationObserver.observe(this.element, { childList: true, subtree: true })
  }

  refresh() {
    this.matchSelectorInTree(this.element, element => {
      this.delegate?.selectorMatched(element)
    })
  }

  processMutations(mutations) {
    for (const mutation of mutations) {
      this.processRemovedNodes(mutation.removedNodes)
      this.processAddedNodes(mutation.addedNodes)
    }
  }

  processAddedNodes(nodes) {
    for (const node of Array.from(nodes)) {
      if (node.nodeType == Node.ELEMENT_NODE) {
        this.matchSelectorInTree(node, element => {
          this.delegate?.selectorMatched(element)
        })
      }
    }
  }

  processRemovedNodes(nodes) {
    for (const node of Array.from(nodes)) {
      if (node.nodeType == Node.ELEMENT_NODE) {
        this.matchSelectorInTree(node, element => {
          this.delegate?.matchedSelectorRemoved(element)
        })
      }
    }
  }

  matchSelectorInTree(tree, callback) {
    return tree.querySelectorAll(this.selector).forEach(element => {
      callback(element)
    })
  }
}
