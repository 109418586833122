import Application_controller from "./application_controller";

// Connects to data-controller="offer-indicative-helper"
export default class extends Application_controller {
  static targets = ["dataSet"]
  connect() {}
  updateContent(event) {
    let select = event.target.options;
    if (event.target.options[select.selectedIndex].value) {
      let trix_editor = document.getElementsByTagName('trix-editor')[0];
      trix_editor.editor.insertString(event.target.options[select.selectedIndex].value);
      select.selectedIndex = 0;
    }
  }
}
