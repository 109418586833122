import ApplicationController from "./application_controller";
import { parseIntlFloat } from "../helpers/number_helper"

export default class extends ApplicationController {
  format() {
    this.element.value = parseIntlFloat(this.element.value)
  }

  delimit() {
    // check if this.element.value contains a comma delimiter
    // if so, replace it with a period
    if (this.element.value.indexOf(",") > -1) {
      //replace the last comma with a period
      this.element.value = this.element.value.replace(",", ".")
    }
  }
}
