import ApplicationController from "./application_controller";
import MicroModal from "micromodal";
// Connects to data-controller="warning-popup"
export default class extends ApplicationController {
  static values = {
    active: Boolean,
    modalId: String
  }
  connect() {
    if(this.hasActiveValue && this.activeValue == true) {
      MicroModal.show(this.modalIdValue)
    }
  }
}
