import ApplicationController from "./application_controller";
import {destroy, post} from "@rails/request.js";

export default class extends ApplicationController {
  static targets = [ 'form', 'caption', 'captionTemplate', 'results', 'favorites', 'selected','resultsTab', 'favoritesTab', 'selectedTab', 'searchId', 'selectedService' ]
  Tab_Current;
  filterFavorites;
  selectedServiceContent;
  connect() {
    this.Tab_Current = 1;
    let oldHref = document.location.href;
    let change_url_data = () => {
      let selectedService = JSON.parse(this.selectedServiceTarget.getAttribute("value"));
      selectedService.forEach((item) => {
        this.nodesForServiceId(item).forEach(service => {
          service.select()
        })
      })
      this.updateUI(selectedService.length)
    }

    let observer = new MutationObserver(function(mutations) {
      mutations.forEach(function(mutation) {
        if (oldHref != document.location.href) {
          oldHref = document.location.href;
          /* Changed ! your code here */
          change_url_data()
        }
      });
    });
    let config = {
      childList: true,
      subtree: true
    };
    observer.observe(document, config);

    let selectedService = JSON.parse(this.selectedServiceTarget.getAttribute("value"));
    selectedService.forEach((item) => {
      this.nodesForServiceId(item).forEach(service => {
        service.select()
      })
    })
    this.updateUI(selectedService.length)

  }

  disconnect() {
  }

  async serviceSelected(event) {
    let searchResult = event.target.closest("search-result")
    let searchId = this.searchIdTarget.getAttribute("value");
    let uival;
    let data = this.firstNodeForServiceId(searchResult.serviceId)
    let selectedService = JSON.parse(this.selectedServiceTarget.getAttribute("value"));
    if(this.Tab_Current == 1 || this.Tab_Current == 2) {
      if(data.isSelected) {
        await destroy(`/searches/${searchId}/delete_selected?selected=0&company_id=${data.getAttribute("data-service-id")}`, {responseKind: "turbo-stream"})
        uival = selectedService.length - 1
    } else {
        await post(`/searches/${searchId}/save_selected?selected=0&company_id=${data.getAttribute("data-service-id")}&distance_unit=${data.getAttribute("data-distance-unit")}&distance_input=${data.getAttribute("data-distance-input")}`, {responseKind: "turbo-stream"})
        uival = selectedService.length + 1
      }
    }

    this.nodesForServiceId(searchResult.serviceId).forEach(node => {
      if(node.isSelected) {
        node.deselect()
      } else {
        node.select()
      }
    })

    this.updateUI(uival)
  }

  async serviceSelectedSaved(event) {
    let searchResult = event.target.closest("search-result")
    let searchId = this.searchIdTarget.getAttribute("value");
    let uival;
    let selectedService = JSON.parse(this.selectedServiceTarget.getAttribute("value"));
    let selectSearchResult = this.element.querySelector(`search-result[data-service-selected-id="${searchResult.serviceSelectedId}"]`)
    if(selectSearchResult.isSelected) {
      await destroy(`/searches/${searchId}/delete_selected?company_id=${selectSearchResult.getAttribute("data-service-selected-id")}`, {responseKind: "turbo-stream"})
      selectSearchResult.deselect()
      uival = selectedService.length - 1
    } else {
      await post(`/searches/${searchId}/save_selected?company_id=${selectSearchResult.getAttribute("data-service-selected-id")}&distance_unit=${selectSearchResult.getAttribute("data-distance-unit")}&distance_input=${selectSearchResult.getAttribute("data-distance-input")}`, {responseKind: "turbo-stream"})
      selectSearchResult.select()
      uival = selectedService.length + 1
    }
    this.updateUI(uival)
  }

  showResults() {
    this.Tab_Current = 1;
    this.resultsTarget.classList.remove("absolute", "left-[-9999px]")
    this.resultsTabTarget.classList.add("active")
    this.favoritesTarget.classList.add("absolute", "left-[-9999px]")
    this.favoritesTabTarget.classList.remove("active")
    this.selectedTarget.classList.add("absolute", "left-[-9999px]")
    this.selectedTabTarget.classList.remove("active")
    this.filterFavorites = {};
    this.selectedServiceContent = {};
    let searchId = this.searchIdTarget.getAttribute("value");

    post(`/searches/update_selected_bar?search_id=${searchId}`, {
      responseKind: 'turbo-stream'
    })
    post(`/searches/update_favorites_bar?`, {
      responseKind: 'turbo-stream'
    })
    let selectedService = JSON.parse(this.selectedServiceTarget.getAttribute("value"));
    this.getAllSearchNodeService().forEach(item => {
      let find = selectedService.findIndex((data) => item.serviceId == data )
      if (find != -1) item.select()
      else item.deselect()
    })
  }

  showFavorites() {
    this.Tab_Current = 2;
    this.filterFavorites = {};
    this.selectedServiceContent = {};
    this.favoritesTarget.classList.remove("absolute", "left-[-9999px]")
    this.favoritesTabTarget.classList.add("active")
    this.resultsTarget.classList.add("absolute", "left-[-9999px]")
    this.resultsTabTarget.classList.remove("active")
    this.selectedTarget.classList.add("absolute", "left-[-9999px]")
    this.selectedTabTarget.classList.remove("active")
    let searchId = this.searchIdTarget.getAttribute("value");

    post(`/searches/update_selected_bar?search_id=${searchId}`, {
      responseKind: 'turbo-stream'
    })
    post(`/searches/update_favorites_bar?`, {
      responseKind: 'turbo-stream'
    })
    let selectedService = JSON.parse(this.selectedServiceTarget.getAttribute("value"));
    this.getAllSearchNodeService().forEach(item => {
      let find = selectedService.findIndex((data) => item.serviceId == data )
      if (find != -1) item.select()
      else item.deselect()
    })
  }

  showSelected() {
    this.Tab_Current = 3;
    this.filterFavorites = {};
    this.selectedServiceContent = {};
    this.favoritesTarget.classList.add("absolute", "left-[-9999px]")
    this.resultsTarget.classList.add("absolute", "left-[-9999px]")
    this.favoritesTabTarget.classList.remove("active")
    this.resultsTabTarget.classList.remove("active")
    this.selectedTarget.classList.remove("absolute", "left-[-9999px]")
    this.selectedTabTarget.classList.add("active")
    let searchId = this.searchIdTarget.getAttribute("value");

    post(`/searches/update_selected_bar?search_id=${searchId}`, {
      responseKind: 'turbo-stream'
    })
    post(`/searches/update_favorites_bar?`, {
      responseKind: 'turbo-stream'
    })
    let selectSearchResult = this.element.querySelectorAll(`search-result[data-service-selected-id]`)
    selectSearchResult.forEach(item => {
      let find = JSON.parse(this.selectedServiceTarget.getAttribute("value")).findIndex( (data) => data == item.serviceSelectedId  )
      if (find != -1 ) item.select();
      else item.deselect()
    })
  }

  refreshSelectedServices() {
    this.selectedServices.selectedServiceIds.forEach(serviceId => {
      this.nodesForServiceId(serviceId).forEach(service => {
        service.select()
      })
    })
  }

  updateUI(value) {
    if (value == 0) {
      this.formTarget.classList.add("hidden")
    } else {
      this.formTarget.classList.remove("hidden")
      this.updateCaption(value)
    }
  }

  addServiceToSelection(serviceId) {
    let formInputs = this.firstNodeForServiceId(serviceId).formInputs
    this.selectedServices.addService(serviceId, formInputs)
  }

  updateCaption(size) {
    this.captionTarget.innerHTML = this.captionTemplateTarget.innerHTML.replace(/COUNT/, size)
  }

  firstNodeForServiceId(id) {
    return this.element.querySelector(`search-result[data-service-id="${id}"]`)
  }

  nodesForServiceId(id) {
    return this.element.querySelectorAll(`search-result[data-service-id="${id}"]`)
  }

  getAllSearchNodeService() {
    return this.element.querySelectorAll(`search-result[data-service-id]`)
  }

  get selectedServices() {
    return this.element.querySelector("selected-services")
  }

  get favoritesServices() {
    return this.element.querySelectorAll('search-result[id^="search_favorite"]')
  }

  changeFilter(e) {
    let changeValue = e.target.getAttribute('value').split(':')

    let findExistsData = this.filterFavorites && this.filterFavorites[changeValue[0]]
    if(findExistsData && findExistsData === changeValue[1]) {
      // remove hidden
      let changeFilterAction = [
        {
          filter: "country", exists: [
            ["company", "strategic_provider"],
            ["company"],
            ["strategic_provider"],
            ["country"]
          ]
        },
        {
          filter: "company", exists: [
            ["country", "strategic_provider"],
            ["country"],
            ["strategic_provider"],
            ["company"]
          ]
        },
        {
          filter: "strategic_provider", exists: [
            ["country", "company"],
            ["company"],
            ["country"],
            ["strategic_provider"]
          ]
        },
      ]
      let localize = {
        strategic_provider: "data-strategic-provider",
        country: "data-country",
        company: "data-service-company-type"
      }
      if (changeValue[0] == 'company' || changeValue[0] == 'country' || changeValue[0] == 'strategic_provider') {
        let resource = changeFilterAction.find((item) => item.filter == changeValue[0])
        if (resource) {
          this.favoritesServices.forEach((item) => {
            for (let i = 0 ; i < resource.exists.length ; i++) {
              if ( resource.exists[i].length == 2) {
                if(
                  this.filterFavorites[resource.exists[i][0]] == item.getAttribute(localize[resource.exists[i][0]]) &&
                  this.filterFavorites[resource.exists[i][1]] == item.getAttribute(localize[resource.exists[i][1]])
                ) {
                  if (item.classList.contains('hidden')) {
                    item.classList.remove('hidden')
                  }
                }
              }

              if ( resource.exists[i].length == 1) {
                if(
                  this.filterFavorites[resource.exists[i][0]] &&
                  this.filterFavorites[resource.exists[i][0]] == item.getAttribute(localize[resource.exists[i][0]])
                ) {
                  if (!this.filterFavorites[resource.exists[0][0]]  && !this.filterFavorites[resource.exists[0][1]]) {
                    if (item.classList.contains('hidden')) {
                      item.classList.remove('hidden')
                    }
                  }
                  else if (this.filterFavorites[resource.exists[0][0]]) {
                    if(this.filterFavorites[resource.exists[0][0]] == item.getAttribute(localize[resource.exists[0][0]])) {
                      if (item.classList.contains('hidden')) {
                        item.classList.remove('hidden')
                      }
                    } else {
                      if (!item.classList.contains('hidden')) {
                        item.classList.add('hidden')
                      }
                    }
                  }
                  else if (this.filterFavorites[resource.exists[0][1]]) {
                    if(this.filterFavorites[resource.exists[0][1]] == item.getAttribute(localize[resource.exists[0][1]])) {
                      if (item.classList.contains('hidden')) {
                        item.classList.remove('hidden')
                      }
                    } else {
                      if (!item.classList.contains('hidden')) {
                        item.classList.add('hidden')
                      }
                    }
                  }
                }
              }
            }
          })
        }
      }
      if (changeValue[0] != 'company' || changeValue[0] != 'country' || changeValue[0] != 'strategic_provider') {
        this.favoritesServices.forEach((item) => {
          item.style.order = 0
        })
      }
      delete this.filterFavorites[changeValue[0]]
    } else {
      // add hidden
      let changeFilterAction = [
        {
          filter: "country", exists: [
            ["company", "strategic_provider"],
            ["company"],
            ["strategic_provider"],
            ["country"]
          ]
        },
        {
          filter: "company", exists: [
            ["country", "strategic_provider"],
            ["country"],
            ["strategic_provider"],
            ["company"]
          ]
        },
        {
          filter: "strategic_provider", exists: [
            ["country", "company"],
            ["company"],
            ["country"],
            ["strategic_provider"]
          ]
        },
      ]
      let localize = {
        strategic_provider: "data-strategic-provider",
        country: "data-country",
        company: "data-service-company-type"
      }
      let resource = changeFilterAction.find((item) => item.filter == changeValue[0])
      if( resource ) {
        this.favoritesServices.forEach((item) => {
          for (let i = 0; i < resource.exists.length ; i++) {
            if (resource.exists[i].length == 2) {
              if(this.filterFavorites[resource.exists[i][0]] && this.filterFavorites[resource.exists[i][1]]) {
                if (
                  changeValue[1] == item.getAttribute(localize[changeValue[0]]) &&
                  this.filterFavorites[resource.exists[i][0]] == item.getAttribute(localize[resource.exists[i][0]]) &&
                  this.filterFavorites[resource.exists[i][1]] == item.getAttribute(localize[resource.exists[i][1]])
                ) {
                  if (item.classList.contains('hidden')) {
                    item.classList.remove('hidden')
                  }
                  break;
                }
              }
            }
            if (resource.exists[i].length == 1 && resource.exists[i][0] == resource.filter && !this.filterFavorites[resource.exists[0][0]]  && !this.filterFavorites[resource.exists[0][1]]) {
                if (changeValue[1] == item.getAttribute(localize[changeValue[0]])) {
                  if (item.classList.contains('hidden')) {
                    item.classList.remove('hidden')
                  }
                } else {
                  if (!item.classList.contains('hidden')) {
                    item.classList.add('hidden')
                  }
                }
            }
            if (resource.exists[i].length == 1) {
              if(this.filterFavorites[resource.exists[i][0]] && resource.exists[i][0] != resource.filter) {
                if (
                  changeValue[1] == item.getAttribute(localize[changeValue[0]]) &&
                  this.filterFavorites[resource.exists[i][0]] == item.getAttribute(localize[resource.exists[i][0]])
                ) {
                  if (item.classList.contains('hidden')) {
                    item.classList.remove('hidden')
                  }
                } else {
                  if (!item.classList.contains('hidden')) {
                    item.classList.add('hidden')
                  }
                }
              }
            }
          }
        })
      }

      if(changeValue[0] == 'time_delivery') {
        let sortingArray = [];
        this.favoritesServices.forEach((item) => {
          sortingArray.push(parseInt(item.getAttribute('data-time-delivery')))
        })
        sortingArray.sort((a, b) => {
          if (changeValue[1] == 'ASC') return b-a || isNaN(b)-isNaN(a)
          else return a-b || isNaN(a)-isNaN(b);
        });
        this.favoritesServices.forEach((item) => {
          let search = sortingArray.findIndex((data) =>  data == parseInt(item.getAttribute('data-time-delivery')))
          item.style.order = search === -1 ? 999999 : search
        })
      }
      if(changeValue[0] == 'distance') {
        let sortingArray = [];
        this.favoritesServices.forEach((item) => {
          sortingArray.push(parseInt(item.getAttribute('data-distance-input')))
        })
        sortingArray.sort((a, b) => {
          if (changeValue[1] == 'ASC') return b-a || isNaN(b)-isNaN(a)
          else return a-b || isNaN(a)-isNaN(b)
        });
        this.favoritesServices.forEach((item) => {
          let search = sortingArray.findIndex((data) =>  data == parseInt(item.getAttribute('data-distance-input')))
          item.style.order = search === -1 ? 999999 : search
        })
      }
      if(changeValue[0] == 'price') {
        let sortingArray = [];
        this.favoritesServices.forEach((item) => {
          sortingArray.push(parseInt(item.getAttribute('data-price')))
        })
        sortingArray.sort((a, b) => {
          if (changeValue[1] == 'ASC') return b-a || isNaN(b)-isNaN(a)
          else return a-b || isNaN(a)-isNaN(b)
        });
        this.favoritesServices.forEach((item) => {
          let search = sortingArray.findIndex((data) =>  data == parseInt(item.getAttribute('data-price')))
          item.style.order = search === -1 ? 999999 : search
        })
      }
      if (changeValue[0] == 'company' || changeValue[0] == 'country' || changeValue[0] == 'strategic_provider') {
        this.filterFavorites = {
          ...this.filterFavorites,
          [changeValue[0]] : changeValue[1]
        }
      } else {
        this.filterFavorites = {
          ...this.filterFavorites,
          'distance': '',
          'price': '',
          'time_delivery': '',
          [changeValue[0]] : changeValue[1]
        }
      }
    }
    let searchId = this.searchIdTarget.getAttribute("value");
    let query = `searchId=${searchId}`
    if (this.filterFavorites && Object.keys(this.filterFavorites).length > 0) {
      for(let item = 0 ; item < Object.keys(this.filterFavorites).length; item ++) {
        query += `&${Object.keys(this.filterFavorites)[item]}=${this.filterFavorites[Object.keys(this.filterFavorites)[item]]}`
      }
    }

    post(`/searches/update_favorites_bar?${query}`, {
      responseKind: 'turbo-stream'
    })
  }
  get selectedResult () {
    return this.element.querySelectorAll('[id^="selected_result"]')
  }
  changeFilterSelected(e) {
    let changeValue = e.target.getAttribute('value').split(':')
    let findExistsData = this.selectedServiceContent && this.selectedServiceContent[changeValue[0]]
    if(findExistsData && findExistsData === changeValue[1]) {
      let changeFilterAction = [
        {
          filter: "country", exists: [
            ["company", "strategic_provider"],
            ["company"],
            ["strategic_provider"],
            ["country"]
          ]
        },
        {
          filter: "company", exists: [
            ["country", "strategic_provider"],
            ["country"],
            ["strategic_provider"],
            ["company"]
          ]
        },
        {
          filter: "strategic_provider", exists: [
            ["country", "company"],
            ["company"],
            ["country"],
            ["strategic_provider"]
          ]
        },
      ]
      let localize = {
        strategic_provider: "data-strategic-provider",
        country: "data-country",
        company: "data-service-company-type"
      }
      if (changeValue[0] == 'company' || changeValue[0] == 'country' || changeValue[0] == 'strategic_provider') {
        let resource = changeFilterAction.find((item) => item.filter == changeValue[0])
        if (resource) {
          this.selectedResult.forEach((item) => {
            for (let i = 0 ; i < resource.exists.length ; i++) {
              if ( resource.exists[i].length == 2) {
                if(
                  this.selectedServiceContent[resource.exists[i][0]] == item.firstElementChild.getAttribute(localize[resource.exists[i][0]]) &&
                  this.selectedServiceContent[resource.exists[i][1]] == item.firstElementChild.getAttribute(localize[resource.exists[i][1]])
                ) {
                  if (item.classList.contains('hidden')) {
                    item.classList.remove('hidden')
                  }
                }
              }

              if ( resource.exists[i].length == 1) {
                if(
                  this.selectedServiceContent[resource.exists[i][0]] &&
                  this.selectedServiceContent[resource.exists[i][0]] == item.firstElementChild.getAttribute(localize[resource.exists[i][0]])
                ) {
                  if (!this.selectedServiceContent[resource.exists[0][0]]  && !this.selectedServiceContent[resource.exists[0][1]]) {
                    if (item.classList.contains('hidden')) {
                      item.classList.remove('hidden')
                    }
                  }
                  else if (this.selectedServiceContent[resource.exists[0][0]]) {
                    if(this.selectedServiceContent[resource.exists[0][0]] == item.firstElementChild.getAttribute(localize[resource.exists[0][0]])) {
                      if (item.classList.contains('hidden')) {
                        item.classList.remove('hidden')
                      }
                    } else {
                      if (!item.classList.contains('hidden')) {
                        item.classList.add('hidden')
                      }
                    }
                  }
                  else if (this.selectedServiceContent[resource.exists[0][1]]) {
                    if(this.selectedServiceContent[resource.exists[0][1]] == item.firstElementChild.getAttribute(localize[resource.exists[0][1]])) {
                      if (item.classList.contains('hidden')) {
                        item.classList.remove('hidden')
                      }
                    } else {
                      if (!item.classList.contains('hidden')) {
                        item.classList.add('hidden')
                      }
                    }
                  }
                }
              }
            }
          })
        }
      }
      if (changeValue[0] != 'company' || changeValue[0] != 'country' || changeValue[0] != 'strategic_provider') {
        this.selectedResult.forEach((item) => {
          item.style.order = 0
        })
      }

      delete this.selectedServiceContent[changeValue[0]]
    } else {
      let changeFilterAction = [
        {
          filter: "country", exists: [
            ["company", "strategic_provider"],
            ["company"],
            ["strategic_provider"],
            ["country"]
          ]
        },
        {
          filter: "company", exists: [
            ["country", "strategic_provider"],
            ["country"],
            ["strategic_provider"],
            ["company"]
          ]
        },
        {
          filter: "strategic_provider", exists: [
            ["country", "company"],
            ["company"],
            ["country"],
            ["strategic_provider"]
          ]
        },
      ]
      let localize = {
        strategic_provider: "data-strategic-provider",
        country: "data-country",
        company: "data-service-company-type"
      }
      let resource = changeFilterAction.find((item) => item.filter == changeValue[0])
      if( resource ) {
        // this.selectedResult.forEach((item) => {
        //   let breakData = false;
        //   for (let i = 0; i < resource.exists.length ; i++) {
        //     if(resource.exists[i].length == 2) {
        //       if(this.selectedServiceContent[resource.exists[i][0]] && this.selectedServiceContent[resource.exists[i][1]]) {
        //         if (
        //           changeValue[1] == item.firstElementChild.getAttribute(localize[changeValue[0]]) &&
        //           this.selectedServiceContent[resource.exists[i][0]] == item.firstElementChild.getAttribute(localize[resource.exists[i][0]]) &&
        //           this.selectedServiceContent[resource.exists[i][1]] == item.firstElementChild.getAttribute(localize[resource.exists[i][1]])
        //         ) {
        //           if (item.classList.contains('hidden')) {
        //             item.classList.remove('hidden')
        //           }
        //           breakData = true;
        //           break;
        //         }
        //       }
        //     }
        //     if (resource.exists[i].length == 1 && resource.exists[i][0] == resource.filter && !this.selectedServiceContent[resource.exists[0][0]]  && !this.selectedServiceContent[resource.exists[0][1]]) {
        //       if (changeValue[1] == item.firstElementChild.getAttribute(localize[changeValue[0]])) {
        //         if (item.classList.contains('hidden')) {
        //           item.classList.remove('hidden')
        //         }
        //       } else {
        //         if (!item.classList.contains('hidden')) {
        //           item.classList.add('hidden')
        //         }
        //       }
        //     }
        //     if(
        //       resource.exists[i].length == 1 && this.selectedServiceContent[resource.exists[i][0]] == item.firstElementChild.getAttribute(localize[resource.exists[i][0]])) {
        //       if(this.selectedServiceContent[resource.exists[i][0]]) {
        //         if (
        //           changeValue[1] == item.firstElementChild.getAttribute(localize[changeValue[0]]) &&
        //           this.selectedServiceContent[resource.exists[i][0]] == item.firstElementChild.getAttribute(localize[resource.exists[i][0]])
        //         ) {
        //           if (item.classList.contains('hidden')) {
        //             item.classList.remove('hidden')
        //           }
        //           breakData = true;
        //           break;
        //         }
        //       }
        //     }
        //   }
        //   if (!breakData) {
        //     if(changeValue[1] !== item.firstElementChild.getAttribute(localize[changeValue[0]])) {
        //       if (!item.classList.contains('hidden')) {
        //         item.classList.add('hidden')
        //       }
        //     }
        //     else {
        //       item.classList.remove('hidden')
        //     }
        //   }
        // })
        this.selectedResult.forEach((item) => {
            for (let i = 0; i < resource.exists.length ; i++) {
              if (resource.exists[i].length == 2) {
                if(this.selectedServiceContent[resource.exists[i][0]] && this.selectedServiceContent[resource.exists[i][1]]) {
                  if (
                    changeValue[1] == item.getAttribute(localize[changeValue[0]]) &&
                    this.selectedServiceContent[resource.exists[i][0]] == item.firstElementChild.getAttribute(localize[resource.exists[i][0]]) &&
                    this.selectedServiceContent[resource.exists[i][1]] == item.firstElementChild.getAttribute(localize[resource.exists[i][1]])
                  ) {
                    if (item.classList.contains('hidden')) {
                      item.classList.remove('hidden')
                    }
                    break;
                  }
                }
              }
              if (resource.exists[i].length == 1 && resource.exists[i][0] == resource.filter && !this.selectedServiceContent[resource.exists[0][0]]  && !this.selectedServiceContent[resource.exists[0][1]]) {
                if (changeValue[1] == item.firstElementChild.getAttribute(localize[changeValue[0]])) {
                  if (item.classList.contains('hidden')) {
                    item.classList.remove('hidden')
                  }
                } else {
                  if (!item.classList.contains('hidden')) {
                    item.classList.add('hidden')
                  }
                }
              }
              if (resource.exists[i].length == 1) {
                if(this.selectedServiceContent[resource.exists[i][0]] && resource.exists[i][0] != resource.filter) {
                  if (
                    changeValue[1] == item.firstElementChild.getAttribute(localize[changeValue[0]]) &&
                    this.selectedServiceContent[resource.exists[i][0]] == item.firstElementChild.getAttribute(localize[resource.exists[i][0]])
                  ) {
                    if (item.classList.contains('hidden')) {
                      item.classList.remove('hidden')
                    }
                  } else {
                    if (!item.classList.contains('hidden')) {
                      item.classList.add('hidden')
                    }
                  }
                }
              }
            }
          })
      }

      if(changeValue[0] == 'time_delivery') {
        let sortingArray = [];
        this.selectedResult.forEach((item) => {
          sortingArray.push(parseInt(item.firstElementChild.getAttribute('data-time-delivery')))
        })
        sortingArray.sort((a, b) => {
          if (changeValue[1] == 'ASC') return b-a || isNaN(b)-isNaN(a)
          else return a-b || isNaN(a)-isNaN(b);
        });
        this.selectedResult.forEach((item) => {
          let search = sortingArray.findIndex((data) =>  data == parseInt(item.firstElementChild.getAttribute('data-time-delivery')))
          item.style.order = search === -1 ? 999999 : search
        })
      }
      if(changeValue[0] == 'distance') {
        let sortingArray = [];
        this.selectedResult.forEach((item) => {
          sortingArray.push(parseInt(item.firstElementChild.getAttribute('data-distance-input')))
        })
        sortingArray.sort((a, b) => {
          if (changeValue[1] == 'ASC') return b-a || isNaN(b)-isNaN(a)
          else return a-b || isNaN(a)-isNaN(b)
        });
        this.selectedResult.forEach((item) => {
          let search = sortingArray.findIndex((data) =>  data == parseInt(item.firstElementChild.getAttribute('data-distance-input')))
          item.style.order = search === -1 ? 999999 : search
        })
      }
      if(changeValue[0] == 'price') {
        let sortingArray = [];
        this.selectedResult.forEach((item) => {
          sortingArray.push(parseInt(item.firstElementChild.getAttribute('data-price')))
        })
        sortingArray.sort((a, b) => {
          if (changeValue[1] == 'ASC') return b-a || isNaN(b)-isNaN(a)
          else return a-b || isNaN(a)-isNaN(b)
        });
        this.selectedResult.forEach((item) => {
          let search = sortingArray.findIndex((data) =>  data == parseInt(item.firstElementChild.getAttribute('data-price')))
          item.style.order = search === -1 ? 999999 : search
        })
      }

      if (changeValue[0] == 'company' || changeValue[0] == 'country' || changeValue[0] == 'strategic_provider') {
        this.selectedServiceContent = {
          ...this.selectedServiceContent,
          [changeValue[0]] : changeValue[1]
        }
      } else {
        this.selectedServiceContent = {
          ...this.selectedServiceContent,
          'distance': '',
          'price': '',
          'time_delivery': '',
          [changeValue[0]] : changeValue[1]
        }
      }
    }
    let searchId = this.searchIdTarget.getAttribute("value");
    let query = `search_id=${searchId}`
    if (this.selectedServiceContent && Object.keys(this.selectedServiceContent).length > 0) {
      for(let item = 0 ; item < Object.keys(this.selectedServiceContent).length; item ++) {
        query += `&${Object.keys(this.selectedServiceContent)[item]}=${this.selectedServiceContent[Object.keys(this.selectedServiceContent)[item]]}`
      }
    }

    post(`/searches/update_selected_bar?${query}`, {
      responseKind: 'turbo-stream'
    })
  }
}
