import ApplicationController from "./application_controller";
import InputErrorsHelper from "../helpers/input_errors_helper";
import { get } from "@rails/request.js";
import Moment from "moment";


// Connects to data-controller="gauge-management"
export default class extends ApplicationController {

  static targets = [ 'calibration_interval', 'calibration_interval_unit' ]

  async validateForm(event) {
    event.preventDefault()

    const requiredFields = ['inventory_number', 'device_type', 'device_model', 'device_manufacturer',
                            'calibration_interval', 'calibration_interval_unit', 'measurement_category_id']

    let blankError = InputErrorsHelper.showCantBeBlankError('calibrations_gauge_', requiredFields)
    let uniqError = await this.showUniqErrors();
    let lastCalibrationDateError = this.checkLastCalibrationDateIsInFuture()
    let selectedStatementError = this.showStatementsOfConformityError();
    let calibrationIntervalError = this.checkCalibrationInterval();

    if(!blankError && !uniqError && !lastCalibrationDateError && !selectedStatementError && !calibrationIntervalError) {
      document.getElementById('gauge-form').submit()
    }
  }

  // validate uniqueness
  async gaugeInventoryNumberExists(inventory_number, gauge_id) {

    if(inventory_number.length > 0) {
      let response = await get(`/gauges/exists?inventory_number=${inventory_number}&gauge_id=${gauge_id}`, {})
      const body = await response.json

      if(body.exists) {
       return true;
      }
    }

    return false;
  }

  async checkUniquness() {
    let gauge_id = document.getElementById('calibrations_gauge_gauge_id').value
    let inventory_number =  document.getElementById('calibrations_gauge_inventory_number').value
    let inventory_number_exists = await this.gaugeInventoryNumberExists(inventory_number, gauge_id)

    let notUniqs = []
    if(inventory_number_exists) {
      notUniqs.push('inventory_number')
    } else {
      if (document.getElementsByClassName('inventory_number_uniq_error').length > 0) {
        document.getElementsByClassName('inventory_number_uniq_error')[0].remove()
      }
      document.getElementById('calibrations_gauge_inventory_number').classList.remove('gauge-required-input')
    }
    return notUniqs;
  }

  async showUniqErrors() {
    let showError = false;
    let notUniqs = await this.checkUniquness();

    for(const element of [...notUniqs]) {
      if(!document.getElementsByClassName(`${element}_uniq_error`).length) {
        if (document.getElementsByClassName(`${element}_uniq_error`).length > 0) {
          document.getElementsByClassName(`${element}_uniq_error`)[0].remove()
        }
        let input = document.getElementById(`calibrations_gauge_${element}`)
        const message = document.createElement('p');
        message.classList.add(`${element}_uniq_error`)
        message.classList.add('field_error')
        if(element === 'serial_number') {
          message.textContent = `this ${element.replace(/_/g, " ")} exists in your gauge list with the given manufacturer`
        } else {
          message.textContent = `this ${element.replace(/_/g, " ")} exists in your gauge list`
        }
        input.parentNode.insertBefore(message, input.nextSibling);
        input.classList.add('gauge-required-input')
        showError = true;
      }
    }

    return showError;
  }

  calculateCalibrationInterval(calibrationInterval, calibrationIntervalUnit) {
    return Moment.duration(calibrationInterval, calibrationIntervalUnit).asDays();
  }

  checkCalibrationInterval() {
    let calibrationInterval = document.getElementById('calibrations_gauge_calibration_interval').value
    let calibrationUnit = document.getElementById('calibrations_gauge_calibration_interval_unit').value
    console.log(calibrationInterval, calibrationUnit)
    let calibrationIntervalInDays = this.calculateCalibrationInterval(calibrationInterval, calibrationUnit)

    let showError = (calibrationIntervalInDays > 5 * 365)
    this.showCalibrationIntervalError(showError);

    if (showError) {
      return true;
    } else {
      return false;
    }
  }

  showCalibrationIntervalError(show) {
    if (document.getElementsByClassName(`calibration_interval_error`).length > 0) {
      let input = document.getElementsByClassName(`calibration_interval_error`)[0]
      input.remove()
      input.classList.remove('gauge-required-input')
    }
    if(show) {
      let input = document.getElementById(`calibrations_gauge_calibration_interval`)
      const message = document.createElement('p');
      message.classList.add(`calibration_interval_error`)
      message.classList.add('field_error')
      message.textContent = `can't be more than 5 years`
      input.parentNode.insertBefore(message, input.nextSibling);
      input.classList.add('gauge-required-input')
    }
  }

  checkLastCalibrationDateIsInFuture() {
    let last_calibration_date = document.getElementById('calibrations_gauge_last_calibration_date').value
    let currentDate = new Date();
    let selectedDate = new Date(last_calibration_date);

    if (selectedDate > currentDate) {
      this.showLastCalibrationDateError(currentDate, selectedDate);
      return true;
    } else {
      this.showLastCalibrationDateError(currentDate, selectedDate);
      return false;
    }
  }

  showLastCalibrationDateError(currentDate, selectedDate) {
    if (document.getElementsByClassName(`last_calibration_date_error`).length > 0) {
      document.getElementsByClassName(`last_calibration_date_error`)[0].remove()
    }
    if(selectedDate > currentDate) {
      let input = document.getElementById(`calibrations_gauge_last_calibration_date`)
      const message = document.createElement('p');
      message.classList.add(`last_calibration_date_error`)
      message.classList.add('field_error')
      message.textContent = `must be in the past`
      input.parentNode.insertBefore(message, input.nextSibling);
      input.classList.add('gauge-required-input')
    }
  }

  // Accreditation additional options validation logic

  showStatementsOfConformityError() {
    if (document.getElementsByClassName(`statements_of_conformity_error`).length > 0) {
      document.getElementsByClassName(`statements_of_conformity_error`)[0].remove()
    }
    if(document.getElementById('calibrations_gauge_accreditation_qualification').value === 'custom_requirement' &&
        document.getElementById('calibrations_gauge_type_of_accreditation').value === '') {
      let input = document.getElementById(`calibrations_gauge_type_of_accreditation`)
      const message = document.createElement('p');
      message.classList.add(`statements_of_conformity_error`)
      message.classList.add('field_error')
      message.textContent = `must be selected a value if type of qualification is custom requirements`
      input.parentNode.insertBefore(message, input.nextSibling);
      input.classList.add('gauge-required-input')
      return true;
    } else {
      return false;
    }
  }

  // RFQ submit request

  submitRequest(event) {
    event.preventDefault()

    let ids = []
    let form = document.getElementById('rfq_request_form');
    document.querySelectorAll('.gauge_checkbox:checked').forEach(checkbox => {
      ids.push(checkbox.id)
    });

    document.getElementById('rfq_request_selected_inventory_numbers_input').value = ids.join(',')

    form.submit()
  }

  // Submit final list

  validateCalibrationForm(event) {
    event.preventDefault()

    this.submitCalibrationRequest();
  }

  submitCalibrationRequest() {
    let ids = []
    let form = document.getElementById('calibration_request_form');
    document.querySelectorAll('.gauge_checkbox:checked').forEach(checkbox => {
      ids.push(checkbox.id)
    });

    document.getElementById('calibration_request_selected_inventory_numbers_input').value = ids.join(',')
    console.log(document.getElementById('calibration_request_selected_inventory_numbers_input').value)

    form.submit()
  }

  // RFQ button and checkboxes logic

  showRfqButton(checked) {
    let request_button = document.getElementById('rfq_for_calibration');

    if(checked) {
      request_button.removeAttribute('disabled');
    } else {
      request_button.setAttribute('disabled', 'disabled');
    }
  }

  selectAllCheckboxes(event) {
    let selectAllCheckbox = document.getElementById('select-all-checkbox');
    let checkboxes = document.querySelectorAll('input[name="gauge_checkbox"]');

    checkboxes.forEach((checkbox) => {
        checkbox.checked = selectAllCheckbox.checked;
    });
    this.showRfqButton(selectAllCheckbox.checked)
  }

  selectCheckbox(event) {
    let checked_checkboxes = document.querySelectorAll('input[name="gauge_checkbox"]:checked').length;
    let checkboxes = document.querySelectorAll('input[name="gauge_checkbox"]').length;
    let selectAllCheckbox = document.getElementById('select-all-checkbox');

    if(checked_checkboxes == checkboxes) {
      selectAllCheckbox.checked = true;
    } else {
      selectAllCheckbox.checked = false;
    }

    if(checked_checkboxes > 0) {
      this.showRfqButton(true)
    } else {
      this.showRfqButton(false)
    }
  }

  // Selected gauges checkbox logic

  showRequestCalibrationButton(checked) {
    let request_button = document.getElementById('request_for_calibration');

    if(checked) {
      request_button.removeAttribute('disabled');
    } else {
      request_button.setAttribute('disabled', 'disabled');
    }
  }

  selectAllCalibrationCheckboxes(event) {
    let selectAllCheckbox = document.getElementById('select-all-calibration-checkbox');
    let checkboxes = document.querySelectorAll('input[name="selected_gauge_checkbox"]');

    checkboxes.forEach((checkbox) => {
        checkbox.checked = selectAllCheckbox.checked;
    });
    this.selectCalibrationCheckbox(selectAllCheckbox.checked)
  }

  selectCalibrationCheckbox(event) {
    let checked_checkboxes = document.querySelectorAll('input[name="selected_gauge_checkbox"]:checked').length;
    let checkboxes = document.querySelectorAll('input[name="selected_gauge_checkbox"]').length;
    let selectAllCheckbox = document.getElementById('select-all-calibration-checkbox');

    if(checked_checkboxes == checkboxes) {
      selectAllCheckbox.checked = true;
    } else {
      selectAllCheckbox.checked = false;
    }

    if(checked_checkboxes > 0) {
      this.showRequestCalibrationButton(true)
    } else {
      this.showRequestCalibrationButton(false)
    }
  }
}
