import ApplicationController from "./application_controller";
import { get } from "@rails/request.js";
import { email_template } from "../constant/template_email_constant";

export default class extends ApplicationController {
  static targets = ["dataSet"]
  async connect() {
    let select = document.getElementById("help_email_template_message_category").options;
    let target = this.dataSetTarget.id;
    get(`/admin/help/rfq_email_template/update_value?targets=${target}&select_model=${select[select.selectedIndex].value}`, {
      responseKind: "turbo-stream"
    })
    let response = await get(`/admin/help/rfq_email_template/update_trix_value?targets=${target}&select_model=${select[select.selectedIndex].value}`, {})
    const body = await response.json
    let trix_editor = document.getElementsByTagName('trix-editor')[0];
    if(!trix_editor.value) {
      trix_editor.value = body.body
    }
  }

  async change_message_category(event) {
    let select = event.target.options;
    let target = this.dataSetTarget.id;

    get(`/admin/help/rfq_email_template/update_value?targets=${target}&select_model=${select[select.selectedIndex].value}`, {
      responseKind: "turbo-stream"
    })
    let response = await get(`/admin/help/rfq_email_template/update_trix_value?targets=${target}&select_model=${select[select.selectedIndex].value}`, {})
    const body = await response.json
    let trix_editor = document.getElementsByTagName('trix-editor')[0];
    trix_editor.value = body.body
  }

  update_trix_content(event) {
    let select = event.target.options;
    if (event.target.options[select.selectedIndex].value) {
      let trix_editor = document.getElementsByTagName('trix-editor')[0];
      trix_editor.editor.insertString(event.target.options[select.selectedIndex].value);
      select.selectedIndex = 0;
    }
  }
}
