import ApplicationController from "./application_controller";
export default class extends ApplicationController {
  static targets = [ 'form', 'caption', 'captionTemplate', 'results', 'favorites', 'resultsTab', 'favoritesTab' ]

  serviceSelected(event) {
    let searchResult = event.target.closest("cancel-request-provider")
    if (searchResult.isSelected) {
      this.selectedServices.removeService(searchResult.serviceId)
    } else {
      this.addServiceToSelection(searchResult.serviceId)
    }

    this.nodesForServiceId(searchResult.serviceId).forEach(node => {
      node.toggleSelection()
    })

    this.updateUI()

  }

  refreshSelectedServices() {
    this.selectedServices.selectedServiceIds.forEach(serviceId => {
      this.nodesForServiceId(serviceId).forEach(service => {
        service.select()
      })
    })
  }

  // Private

  updateUI() {
    if (this.selectedServices.size == 0) {
      this.formTarget.classList.add("hidden")
    } else {
     this.formTarget.classList.remove("hidden")
    }
  }

  addServiceToSelection(serviceId) {
    let formInputs = this.firstNodeForServiceId(serviceId).formInputs
    this.selectedServices.addService(serviceId, formInputs)
  }

  updateCaption() {
  }

  firstNodeForServiceId(id) {
    return this.element.querySelector(`cancel-request-provider[data-service-id="${id}"]`)
  }

  nodesForServiceId(id) {
    return this.element.querySelectorAll(`cancel-request-provider[data-service-id="${id}"]`)
  }

  get selectedServices() {
    return this.element.querySelector("cancel-request-selected-services")
  }

  connect() {
    super.connect();
    let selected = document.getElementById("selected_request_service");
    let selected_value = selected.getAttribute("value")
    if (selected_value) {
      let arrary_id = JSON.parse(selected_value);
      for (let i = 0; i < arrary_id.length; i++) {
        let service = this.firstNodeForServiceId(arrary_id[i]);
        this.addServiceToSelection(arrary_id[i])
        service.toggleSelection()
      }
    } else {
    let data = document.querySelectorAll("cancel-request-provider")
    for (let i = 0; i < data.length; i++) {
      this.addServiceToSelection(data[i].serviceId)
      data[i].toggleSelection()
    }
  }
}
}
