import ApplicationController from "./application_controller";
import { get } from "@rails/request.js";
import { pdf_template } from "../constant/template_pdf_constant"
export default class extends ApplicationController {
  static targets = ["dataSet"]
  connect() {
    let select = document.getElementById("help_pdf_template_category").options;
    let target = this.dataSetTarget.id;
    let trix_editor = document.getElementsByTagName('trix-editor')[0];
    if (!trix_editor.value) {
      trix_editor.value = pdf_template[select[select.selectedIndex].value]
    }
    get(`/admin/help/pdf_template/update_value?targets=${target}&select_model=${select[select.selectedIndex].value}`, {
      responseKind: "turbo-stream"
    })
  }

  change_category(event) {
    let select = event.target.options;
    let target = this.dataSetTarget.id;
    let trix_editor = document.getElementsByTagName('trix-editor')[0];
    trix_editor.value = pdf_template[select[select.selectedIndex].value]
    get(`/admin/help/pdf_template/update_value?targets=${target}&select_model=${select[select.selectedIndex].value}`, {
      responseKind: "turbo-stream"
    })
  }

  update_trix_content(event) {
    let select = event.target.options;
    if (event.target.options[select.selectedIndex].value) {
      let trix_editor = document.getElementsByTagName('trix-editor')[0];
      trix_editor.editor.insertString(event.target.options[select.selectedIndex].value);
      select.selectedIndex = 0;
    }
  }
}
