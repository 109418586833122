import Glide from '@glidejs/glide';
import ApplicationController from "./application_controller";

export default class extends ApplicationController {
  static targets = [ 'carousel' ];

  connect() {
    new Glide(this.carouselTarget).mount()
  }
}
