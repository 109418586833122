import { Controller } from "@hotwired/stimulus"

import {
  getValue,
  getChart,
  getChartStatusAndDestroy,
  getData
} from "../custom_elements/metrics_chart";

export default class extends Controller {
  static targets = [ "popup", "accordion_container", "accordion_canvas", "canvas_chart" ]
  static values = { popup: Object, accordion: Object }
  getDataFromCompanyMetrics(){
    if (this.accordionValue) {
      const data = this.accordionValue
      const { label, unit, totalValue, id, dataMap, labels } = getData(data)
      const container = this.accordion_containerTargets.find(e => e.id === `canvas-container_${id}_${label}`)
      const canvas = this.accordion_canvasTargets.find(e => e.id === `canvas-place_${id}_${label}`)
      const value = getValue(totalValue)
      const chartId = `myChart${id}_${label}`
      if (!Object.values(container.classList).includes('hidden') && Object.values(container.classList) != []) {
        container.classList.add("hidden")
        getChartStatusAndDestroy(chartId)
      }
      else {
        container.classList.remove("hidden")
        if (!this.canvas_chartTargets.find( e => e.id === chartId)){
          canvas.insertAdjacentHTML("beforebegin",`<div class="card"><canvas class="canvas" id="${chartId}" data-metric-target="canvas_chart"></canvas></div>`)
        }
        const ctx =  this.canvas_chartTargets.find( e => e.id === chartId)
          getChart({ ctx: ctx, labels: labels, label: label, value: value, unit: unit, dataMap: dataMap })
          }
        }

  }

  metricMouseOut () {
    if (this.popupValue) {
      const data = this.popupValue
      const label = Object.keys(data)[0]
      const id = Object.values(data)[2]
      const chartId = `myChart-${label}-${id}`
      const popUpChart = this.popupTargets.find(e =>  e.id === chartId)
      getChartStatusAndDestroy(chartId)
      if (popUpChart) {
        popUpChart.classList.remove("show")
      }
    }
  }
  metricMouseOver= () => {
    if (this.popupValue) {
      const data = this.popupValue
      const { label, unit, totalValue, id, dataMap, labels } = getData(data)
      const chartId = `myChart-${label}-${id}`
      const popUpChart = this.popupTargets.find(e =>  e.id === chartId)
      if(popUpChart) {
        popUpChart.classList.toggle("show")
        const value = getValue(totalValue)
        const ctx = popUpChart
        getChart({ ctx: ctx, labels: labels, label: label, value: value, unit: unit, dataMap: dataMap })
      }
    }
  }
}
