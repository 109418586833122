import ApplicationController from "./application_controller";
export default class extends ApplicationController {
  connect() {
    // this.element.scrollIntoView({ behavior: "smooth" })
  }

  scrollIntoId(e) {
    let scroll_to_name = e.target.closest('.stepper-item').getAttribute('name')
    document.getElementById(scroll_to_name)?.scrollIntoView({ behavior: "smooth" })
  }
}
