import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["bundle"]
   addOpenClassToGroup(event){
    if(this.bundleTarget.classList.contains("open")){
       this.bundleTarget.classList.remove("open")
     } else{
       this.bundleTarget.classList.add("open")
     }
  }
}
