import ApplicationController from "./application_controller"
import {get} from "@rails/request.js"

// Connects to data-controller="classifiable-table-header-helper"
export default class extends ApplicationController {
  static targets = ['url', 'model', 'attribute']

  connect() {}

  change_input_value(e) {
    let value = this.urlTarget.getAttribute("value")
    let model = this.modelTarget.getAttribute("value")
    let attribute = this.attributeTarget.getAttribute("value")
    let url = new URL(window.location.href), urlParams = url.searchParams

    urlParams.append('model', model)
    urlParams.append('attribute', attribute)
    urlParams.append('value', e.target.value)
    urlParams.toString()

    get(`${value}?${urlParams.toString()}`, {
      responseKind: "turbo-stream"
    })
  }

  cleanValue(e) {
    e.target.value = ""
  }
}
