import CurrencyHelper from "../helpers/currency_helper"
import ApplicationController from "./application_controller";

export default class extends ApplicationController {
  static targets = [ "field" ]

  connect() {
    this.namedFieldTargets = this.fieldTargets.reduce((obj, target) => {
      return Object.assign(obj, { [target.dataset.serviceFieldName]: target })
    }, {})
  }

  populate(event) {
    const service = event.detail.object;

    if (service) {
      Object.keys(this.namedFieldTargets).forEach(fieldName => {
        const target = this.namedFieldTargets[fieldName];
        var value = service[fieldName];

        if (target.dataset.currency != undefined) {
          value = CurrencyHelper.asNumber(parseFloat(value));
        }
        if (target.dataset.disabled) {
          target.disabled = true
        }
        if (value) {
          target.value = value;
          target.dispatchEvent(new CustomEvent('change'))
        }
      })
    } else {
      this.fieldTargets.forEach(target => {
        if (target.disabled) {
          target.dataset.disabled = true;
          target.disabled = false;
        }
      });
    }
  }

  deleteSum() {
    let summingTarget = this.element.querySelector("[data-summing-target]")
    if (summingTarget) {
      summingTarget.removeAttribute("data-summing-target")
    }
  }
}
