import ApplicationController from "./application_controller";

/**
 * The `GuidedTourController` enables users to take a guided tour through the interface.
 * For the usage of the guided tour, see `app/helpers/guided_tour_helper.rb`.
 */

export default class extends ApplicationController {
  static classes = ['active'];
  static values = { activeId: Number, name: String, offset: Number };
  static targets = ['magnifier', 'step'];

  connect() {
    this.positionElements()
  }

  next(event) {
    event.preventDefault();
    if (this.activeIdValue < this.tourLength) this.activeIdValue += 1;
  }

  activeIdValueChanged() {
    this.stepTargets.forEach(element => {
      if (this.isActiveStep(element)) {
        element.classList.add(this.activeClass);
        this.positionElements();
      } else {
        element.classList.remove(this.activeClass);
      }
    });
  }

  positionElements() {
    this.positionActiveStep();
    this.positionMagnifier();
  }

  positionActiveStep() {
    const { guidedTourPosition } = this.activeStep.dataset;
    const dimensions = this.attachedToDimensions(this.activeStep);

    if (guidedTourPosition === 'right') {
      this.activeStep.style.top = `${dimensions.top}px`;
      this.activeStep.style.left = `${dimensions.right + this.offsetValue}px`;
    } else if (guidedTourPosition === 'bottom') {
      this.activeStep.style.top = `${dimensions.bottom + this.offsetValue}px`;
      this.activeStep.style.left = `${dimensions.left}px`;
    }
  }

  positionMagnifier() {
    const dimensions = this.attachedToDimensions(this.activeStep);

    this.magnifierTarget.style.top = `${dimensions.top}px`;
    this.magnifierTarget.style.left = `${dimensions.left}px`;
    this.magnifierTarget.style.width = `${dimensions.width}px`;
    this.magnifierTarget.style.height = `${dimensions.height}px`;
  }

  isActiveStep(element) {
    return parseInt(element.dataset.guidedTourId, 10) === this.activeIdValue;
  }

  /**
   * The `attachedToDimensions` looks up the dimensions of the element which should
   * be highlighted by a tour step.
   *
   * @param {HTMLDomElement} element The active step element
   * @returns {Object}
   */
  attachedToDimensions(element) {
    const { guidedTourSelector } = element.dataset;
    const attachedToElement = document.body.querySelector(guidedTourSelector);

    if (attachedToElement === null) {
      // sometimes we turn off a menu item with flipper or so, and
      // having a badly positioned ill-working help is not really a help:
      // skip this step
      if (this.activeIdValue < this.tourLength) this.activeIdValue += 1;
      throw new Error(
        `A guided tour step highlights a certain HTML element. ` +
          `However, this HTML element can't be found. ` +
          `The selector for this tour step is "${guidedTourSelector}".`
      );
    }

    return attachedToElement.getBoundingClientRect();
  }

  get activeStep() {
    return this.stepTargets.find(el => this.isActiveStep(el));
  }

  get tourLength() {
    return this.stepTargets.length;
  }
}
