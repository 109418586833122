import ApplicationController from "./application_controller";

// Connects to data-controller="tender-modal-update"
export default class extends ApplicationController {
  changeValueContact(e) {
    let tender_date_of_request = document.getElementById("tender_date_of_request").value;
    let tender_customer_order_id = document.getElementById("tender_customer_order_id").value;
    let tender_project_status = document.getElementById("tender_project_status").value;
    let tender_customer_id = document.getElementById("tender_customer_id").value;
    let tender_sales_lead = document.getElementById("tender_sales_lead").value;
    let tender_customer_contacts = document.getElementById("tender_customer_contacts").value;
    let tender_project_lead = document.getElementById("tender_project_lead").value;
    let tender_title = document.getElementById("tender_title").value;
    let tender_frame_contract_id = document.getElementById("tender_frame_contract_id").value;
    let tender_product_qty = document.getElementById("tender_product_qty").value;
    let tender_product_name = document.getElementById("tender_product_name").value;
    let tender_quote_deadline = document.getElementById("tender_quote_deadline").value;
    let tender_need_close = document.getElementById("tender_need_close").value;
    let tender_kor_country = document.getElementById("tender_kor_country").value;
    let tender_service_type = document.getElementById("tender_service_type").value;
    let tender_planned_start_date = document.getElementById("tender_planned_start_date").value;
    let tender_deadline = document.getElementById("tender_deadline").value;
    let tender_description = document.getElementById('tender_description').value;
    let tender_other_remarks = document.getElementById("tender_other_remarks").value;
    let tender_logistics_provider_id = document.getElementById("tender_logistics_provider_id").value;
    let tender_offered_price = document.getElementById("tender_offered_price").value;
    let tender_logistics_notes = document.getElementById("tender_logistics_notes").value;
    let tender_estimated_amount = document.getElementById("tender_estimated_amount").value;
    let tender_rfq_close_date = document.getElementById("tender_rfq_close_date").value;
    let tender_payment_conditions = document.getElementById("tender_payment_conditions").value;
    let tender_delivery_conditions = document.getElementById("tender_delivery_conditions").value;
    let tender_kor_offer_id = document.getElementById("tender_kor_offer_id").value;
    let tender_offered_net_price = document.getElementById("tender_offered_net_price").value;
    let tender_offered_price_unit = document.getElementById("tender_offered_price_unit").value;
    let tender_offer_date = document.getElementById("tender_offer_date").value;
    let tender_offer_deadline = document.getElementById("tender_offer_deadline").value;
    let tender_contract_id = document.getElementById("tender_contract_id").value;
    let tender_ordering_date = document.getElementById("tender_ordering_date").value;
    let tender_po = document.getElementById("tender_po").value;
    let tender_logistics_cost = document.getElementById("tender_logistics_cost").value;
    let tender_logistics_invoice_date = document.getElementById("tender_logistics_invoice_date").value;
    let tender_logistics_invoice_amount = document.getElementById("tender_logistics_invoice_amount").value;
    let tender_logistics_invoice = document.getElementById("tender_logistics_invoice").value;
    let tender_customer_invoice_date = document.getElementById("tender_customer_invoice_date").value;
    let tender_customer_invoice_amount = document.getElementById("tender_customer_invoice_amount").value;
    let tender_customer_invoice_id = document.getElementById("tender_customer_invoice_id").value;

    let tender_date_of_request_buyer_modal = document.getElementById("tender_date_of_request_buyer_modal");
    tender_date_of_request_buyer_modal.value = tender_date_of_request;
    let tender_customer_order_id_buyer_modal = document.getElementById("tender_customer_order_id_buyer_modal");
    tender_customer_order_id_buyer_modal.value = tender_customer_order_id;
    let tender_project_status_buyer_modal = document.getElementById("tender_project_status_buyer_modal");
    tender_project_status_buyer_modal.value = tender_project_status;
    let tender_customer_id_buyer_modal = document.getElementById("tender_customer_id_buyer_modal");
    tender_customer_id_buyer_modal.value = tender_customer_id;
    let tender_sales_lead_buyer_modal = document.getElementById("tender_sales_lead_buyer_modal");
    tender_sales_lead_buyer_modal.value = tender_sales_lead;
    let tender_customer_contacts_buyer_modal = document.getElementById("tender_customer_contacts_buyer_modal");
    tender_customer_contacts_buyer_modal.value = tender_customer_contacts;
    let tender_project_lead_buyer_modal = document.getElementById("tender_project_lead_buyer_modal");
    tender_project_lead_buyer_modal.value = tender_project_lead;
    let tender_title_buyer_modal = document.getElementById("tender_title_buyer_modal");
    tender_title_buyer_modal.value = tender_title;
    let tender_frame_contract_id_buyer_modal = document.getElementById("tender_frame_contract_id_buyer_modal");
    tender_frame_contract_id_buyer_modal.value = tender_frame_contract_id;
    let tender_product_qty_buyer_modal = document.getElementById("tender_product_qty_buyer_modal");
    tender_product_qty_buyer_modal.value = tender_product_qty;
    let tender_product_name_buyer_modal = document.getElementById("tender_product_name_buyer_modal");
    tender_product_name_buyer_modal.value = tender_product_name;
    let tender_quote_deadline_buyer_modal = document.getElementById("tender_quote_deadline_buyer_modal");
    tender_quote_deadline_buyer_modal.value = tender_quote_deadline;
    let tender_need_close_buyer_modal = document.getElementById("tender_need_close_buyer_modal");
    tender_need_close_buyer_modal.value = tender_need_close;

    let tender_kor_country_buyer_modal = document.getElementById("tender_kor_country_buyer_modal");
    tender_kor_country_buyer_modal.value = tender_kor_country;
    let tender_service_type_buyer_modal = document.getElementById("tender_service_type_buyer_modal");
    tender_service_type_buyer_modal.value = tender_service_type;
    let tender_planned_start_date_buyer_modal = document.getElementById("tender_planned_start_date_buyer_modal");
    tender_planned_start_date_buyer_modal.value = tender_planned_start_date;
    let tender_deadline_buyer_modal = document.getElementById("tender_deadline_buyer_modal");
    tender_deadline_buyer_modal.value = tender_deadline;
    let tender_description_buyer_modal = document.getElementById('tender_description_buyer_modal');
    tender_description_buyer_modal.value = tender_description;
    let tender_other_remarks_buyer_modal = document.getElementById("tender_other_remarks_buyer_modal");
    tender_other_remarks_buyer_modal.value = tender_other_remarks;
    let tender_logistics_provider_id_buyer_modal = document.getElementById("tender_logistics_provider_id_buyer_modal");
    tender_logistics_provider_id_buyer_modal.value = tender_logistics_provider_id;
    let tender_offered_price_buyer_modal = document.getElementById("tender_offered_price_buyer_modal");
    tender_offered_price_buyer_modal.value = tender_offered_price;
    let tender_logistics_notes_buyer_modal = document.getElementById("tender_logistics_notes_buyer_modal");
    tender_logistics_notes_buyer_modal.value = tender_logistics_notes;
    let tender_estimated_amount_buyer_modal = document.getElementById("tender_estimated_amount_buyer_modal");
    tender_estimated_amount_buyer_modal.value = tender_estimated_amount;
    let tender_rfq_close_date_buyer_modal = document.getElementById("tender_rfq_close_date_buyer_modal");
    tender_rfq_close_date_buyer_modal.value = tender_rfq_close_date;
    let tender_payment_conditions_buyer_modal = document.getElementById("tender_payment_conditions_buyer_modal");
    tender_payment_conditions_buyer_modal.value = tender_payment_conditions;
    let tender_delivery_conditions_buyer_modal = document.getElementById("tender_delivery_conditions_buyer_modal");
    tender_delivery_conditions_buyer_modal.value = tender_delivery_conditions;
    let tender_kor_offer_id_buyer_modal = document.getElementById("tender_kor_offer_id_buyer_modal");
    tender_kor_offer_id_buyer_modal.value = tender_kor_offer_id;
    let tender_offered_net_price_buyer_modal = document.getElementById("tender_offered_net_price_buyer_modal");
    tender_offered_net_price_buyer_modal.value = tender_offered_net_price;
    let tender_offered_price_unit_buyer_modal = document.getElementById("tender_offered_price_unit_buyer_modal");
    tender_offered_price_unit_buyer_modal.value = tender_offered_price_unit;
    let tender_offer_date_buyer_modal = document.getElementById("tender_offer_date_buyer_modal");
    tender_offer_date_buyer_modal.value = tender_offer_date;
    let tender_offer_deadline_buyer_modal = document.getElementById("tender_offer_deadline_buyer_modal");
    tender_offer_deadline_buyer_modal.value = tender_offer_deadline;
    let tender_contract_id_buyer_modal = document.getElementById("tender_contract_id_buyer_modal");
    tender_contract_id_buyer_modal.value = tender_contract_id;
    let tender_ordering_date_buyer_modal = document.getElementById("tender_ordering_date_buyer_modal");
    tender_ordering_date_buyer_modal.value =tender_ordering_date;
    let tender_po_buyer_modal = document.getElementById("tender_po_buyer_modal");
    tender_po_buyer_modal.value = tender_po;
    let tender_logistics_cost_buyer_modal = document.getElementById("tender_logistics_cost_buyer_modal");
    tender_logistics_cost_buyer_modal.value = tender_logistics_cost;
    let tender_logistics_invoice_date_buyer_modal = document.getElementById("tender_logistics_invoice_date_buyer_modal");
    tender_logistics_invoice_date_buyer_modal.value = tender_logistics_invoice_date;
    let tender_logistics_invoice_amount_buyer_modal = document.getElementById("tender_logistics_invoice_amount_buyer_modal");
    tender_logistics_invoice_amount_buyer_modal.value = tender_logistics_invoice_amount;
    let tender_logistics_invoice_buyer_modal = document.getElementById("tender_logistics_invoice_buyer_modal");
    tender_logistics_invoice_buyer_modal.value = tender_logistics_invoice;
    let tender_customer_invoice_date_buyer_modal = document.getElementById("tender_customer_invoice_date_buyer_modal");
    tender_customer_invoice_date_buyer_modal.value = tender_customer_invoice_date;
    let tender_customer_invoice_amount_buyer_modal = document.getElementById("tender_customer_invoice_amount_buyer_modal");
    tender_customer_invoice_amount_buyer_modal.value = tender_customer_invoice_amount;
    let tender_customer_invoice_id_buyer_modal = document.getElementById("tender_customer_invoice_id_buyer_modal");
    tender_customer_invoice_id_buyer_modal.value = tender_customer_invoice_id;
  }

  changeValueAccount() {
    let tender_date_of_request = document.getElementById("tender_date_of_request").value;
    let tender_customer_order_id = document.getElementById("tender_customer_order_id").value;
    let tender_project_status = document.getElementById("tender_project_status").value;
    let tender_customer_id = document.getElementById("tender_customer_id").value;
    let tender_sales_lead = document.getElementById("tender_sales_lead").value;
    let tender_customer_contacts = document.getElementById("tender_customer_contacts").value;
    let tender_project_lead = document.getElementById("tender_project_lead").value;
    let tender_title = document.getElementById("tender_title").value;
    let tender_frame_contract_id = document.getElementById("tender_frame_contract_id").value;
    let tender_product_qty = document.getElementById("tender_product_qty").value;
    let tender_product_name = document.getElementById("tender_product_name").value;
    let tender_quote_deadline = document.getElementById("tender_quote_deadline").value;
    let tender_need_close = document.getElementById("tender_need_close").value;
    let tender_kor_country = document.getElementById("tender_kor_country").value;
    let tender_service_type = document.getElementById("tender_service_type").value;
    let tender_planned_start_date = document.getElementById("tender_planned_start_date").value;
    let tender_deadline = document.getElementById("tender_deadline").value;
    let tender_description = document.getElementById('tender_description').value;
    let tender_other_remarks = document.getElementById("tender_other_remarks").value;
    let tender_logistics_provider_id = document.getElementById("tender_logistics_provider_id").value;
    let tender_offered_price = document.getElementById("tender_offered_price").value;
    let tender_logistics_notes = document.getElementById("tender_logistics_notes").value;
    let tender_estimated_amount = document.getElementById("tender_estimated_amount").value;
    let tender_rfq_close_date = document.getElementById("tender_rfq_close_date").value;
    let tender_payment_conditions = document.getElementById("tender_payment_conditions").value;
    let tender_delivery_conditions = document.getElementById("tender_delivery_conditions").value;
    let tender_kor_offer_id = document.getElementById("tender_kor_offer_id").value;
    let tender_offered_net_price = document.getElementById("tender_offered_net_price").value;
    let tender_offered_price_unit = document.getElementById("tender_offered_price_unit").value;
    let tender_offer_date = document.getElementById("tender_offer_date").value;
    let tender_offer_deadline = document.getElementById("tender_offer_deadline").value;
    let tender_contract_id = document.getElementById("tender_contract_id").value;
    let tender_ordering_date = document.getElementById("tender_ordering_date").value;
    let tender_po = document.getElementById("tender_po").value;
    let tender_logistics_cost = document.getElementById("tender_logistics_cost").value;
    let tender_logistics_invoice_date = document.getElementById("tender_logistics_invoice_date").value;
    let tender_logistics_invoice_amount = document.getElementById("tender_logistics_invoice_amount").value;
    let tender_logistics_invoice = document.getElementById("tender_logistics_invoice").value;
    let tender_customer_invoice_date = document.getElementById("tender_customer_invoice_date").value;
    let tender_customer_invoice_amount = document.getElementById("tender_customer_invoice_amount").value;
    let tender_customer_invoice_id = document.getElementById("tender_customer_invoice_id").value;

    let tender_date_of_request_contact_modal = document.getElementById("tender_date_of_request_contact_modal");
    tender_date_of_request_contact_modal.value = tender_date_of_request;
    let tender_customer_order_id_contact_modal = document.getElementById("tender_customer_order_id_contact_modal");
    tender_customer_order_id_contact_modal.value = tender_customer_order_id;
    let tender_project_status_contact_modal = document.getElementById("tender_project_status_contact_modal");
    tender_project_status_contact_modal.value = tender_project_status;
    let tender_customer_id_contact_modal = document.getElementById("tender_customer_id_contact_modal");
    tender_customer_id_contact_modal.value = tender_customer_id;
    let tender_sales_lead_contact_modal = document.getElementById("tender_sales_lead_contact_modal");
    tender_sales_lead_contact_modal.value = tender_sales_lead;
    let tender_customer_contacts_contact_modal = document.getElementById("tender_customer_contacts_contact_modal");
    tender_customer_contacts_contact_modal.value = tender_customer_contacts;
    let tender_project_lead_contact_modal = document.getElementById("tender_project_lead_contact_modal");
    tender_project_lead_contact_modal.value = tender_project_lead;
    let tender_title_contact_modal = document.getElementById("tender_title_contact_modal");
    tender_title_contact_modal.value = tender_title;
    let tender_frame_contract_id_contact_modal = document.getElementById("tender_frame_contract_id_contact_modal");
    tender_frame_contract_id_contact_modal.value = tender_frame_contract_id;
    let tender_product_qty_contact_modal = document.getElementById("tender_product_qty_contact_modal");
    tender_product_qty_contact_modal.value = tender_product_qty;
    let tender_product_name_contact_modal = document.getElementById("tender_product_name_contact_modal");
    tender_product_name_contact_modal.value = tender_product_name;
    let tender_quote_deadline_contact_modal = document.getElementById("tender_quote_deadline_contact_modal");
    tender_quote_deadline_contact_modal.value = tender_quote_deadline;
    let tender_need_close_contact_modal = document.getElementById("tender_need_close_contact_modal");
    tender_need_close_contact_modal.value = tender_need_close;

    let tender_kor_country_contact_modal = document.getElementById("tender_kor_country_contact_modal");
    tender_kor_country_contact_modal.value = tender_kor_country;
    let tender_service_type_contact_modal = document.getElementById("tender_service_type_contact_modal");
    tender_service_type_contact_modal.value = tender_service_type;
    let tender_planned_start_date_contact_modal = document.getElementById("tender_planned_start_date_contact_modal");
    tender_planned_start_date_contact_modal.value = tender_planned_start_date;
    let tender_deadline_contact_modal = document.getElementById("tender_deadline_contact_modal");
    tender_deadline_contact_modal.value = tender_deadline;
    let tender_description_contact_modal = document.getElementById('tender_description_contact_modal');
    tender_description_contact_modal.value = tender_description;
    let tender_other_remarks_contact_modal = document.getElementById("tender_other_remarks_contact_modal");
    tender_other_remarks_contact_modal.value = tender_other_remarks;
    let tender_logistics_provider_id_contact_modal = document.getElementById("tender_logistics_provider_id_contact_modal");
    tender_logistics_provider_id_contact_modal.value = tender_logistics_provider_id;
    let tender_offered_price_contact_modal = document.getElementById("tender_offered_price_contact_modal");
    tender_offered_price_contact_modal.value = tender_offered_price;
    let tender_logistics_notes_contact_modal = document.getElementById("tender_logistics_notes_contact_modal");
    tender_logistics_notes_contact_modal.value = tender_logistics_notes;
    let tender_estimated_amount_contact_modal = document.getElementById("tender_estimated_amount_contact_modal");
    tender_estimated_amount_contact_modal.value = tender_estimated_amount;
    let tender_rfq_close_date_contact_modal = document.getElementById("tender_rfq_close_date_contact_modal");
    tender_rfq_close_date_contact_modal.value = tender_rfq_close_date;
    let tender_payment_conditions_contact_modal = document.getElementById("tender_payment_conditions_contact_modal");
    tender_payment_conditions_contact_modal.value = tender_payment_conditions;
    let tender_delivery_conditions_contact_modal = document.getElementById("tender_delivery_conditions_contact_modal");
    tender_delivery_conditions_contact_modal.value = tender_delivery_conditions;
    let tender_kor_offer_id_contact_modal = document.getElementById("tender_kor_offer_id_contact_modal");
    tender_kor_offer_id_contact_modal.value = tender_kor_offer_id;
    let tender_offered_net_price_contact_modal = document.getElementById("tender_offered_net_price_contact_modal");
    tender_offered_net_price_contact_modal.value = tender_offered_net_price;
    let tender_offered_price_unit_contact_modal = document.getElementById("tender_offered_price_unit_contact_modal");
    tender_offered_price_unit_contact_modal.value = tender_offered_price_unit;
    let tender_offer_date_contact_modal = document.getElementById("tender_offer_date_contact_modal");
    tender_offer_date_contact_modal.value = tender_offer_date;
    let tender_offer_deadline_contact_modal = document.getElementById("tender_offer_deadline_contact_modal");
    tender_offer_deadline_contact_modal.value = tender_offer_deadline;
    let tender_contract_id_contact_modal = document.getElementById("tender_contract_id_contact_modal");
    tender_contract_id_contact_modal.value = tender_contract_id;
    let tender_ordering_date_contact_modal = document.getElementById("tender_ordering_date_contact_modal");
    tender_ordering_date_contact_modal.value =tender_ordering_date;
    let tender_po_contact_modal = document.getElementById("tender_po_contact_modal");
    tender_po_contact_modal.value = tender_po;
    let tender_logistics_cost_contact_modal = document.getElementById("tender_logistics_cost_contact_modal");
    tender_logistics_cost_contact_modal.value = tender_logistics_cost;
    let tender_logistics_invoice_date_contact_modal = document.getElementById("tender_logistics_invoice_date_contact_modal");
    tender_logistics_invoice_date_contact_modal.value = tender_logistics_invoice_date;
    let tender_logistics_invoice_amount_contact_modal = document.getElementById("tender_logistics_invoice_amount_contact_modal");
    tender_logistics_invoice_amount_contact_modal.value = tender_logistics_invoice_amount;
    let tender_logistics_invoice_contact_modal = document.getElementById("tender_logistics_invoice_contact_modal");
    tender_logistics_invoice_contact_modal.value = tender_logistics_invoice;
    let tender_customer_invoice_date_contact_modal = document.getElementById("tender_customer_invoice_date_contact_modal");
    tender_customer_invoice_date_contact_modal.value = tender_customer_invoice_date;
    let tender_customer_invoice_amount_contact_modal = document.getElementById("tender_customer_invoice_amount_contact_modal");
    tender_customer_invoice_amount_contact_modal.value = tender_customer_invoice_amount;
    let tender_customer_invoice_id_contact_modal = document.getElementById("tender_customer_invoice_id_contact_modal");
    tender_customer_invoice_id_contact_modal.value = tender_customer_invoice_id;
  }

  changeValueOfRFI(e) {
    let options = e.target.options;
    let open_value = ['storno', 'rfi_rfq_lost']
    let enable_field = document.getElementById("lost_reason");
    let tender_lost_reason = document.getElementById("tender_lost_reason");
    let findOpenValue = open_value.find((item =>  item == options[options.selectedIndex].value))
    if (findOpenValue) {
      if(enable_field.classList.contains('hidden')) {
        enable_field.classList.remove('hidden')
        tender_lost_reason.setAttribute('required', 'required');
      }
    } else {
      if(!enable_field.classList.contains('hidden')) {
        enable_field.classList.add('hidden')
        tender_lost_reason.removeAttribute('required');
      }
    }
  }
}
