import { Controller } from "@hotwired/stimulus"
import {post} from "@rails/request.js";

// Connects to data-controller="toggle-request-project"
export default class extends Controller {
  static targets = ["select"];

  connect() {
    super.connect();
    // this.selectTarget.addEventListener('change', this.onChange.bind(this));
  }

  onChange(e) {
    e.target.form.requestSubmit();
  }
}
