import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["detail"]
  connect() {
    if(this.detailTarget.open) {
      this.detailTarget.classList.add("open")
    }
  }
  addOpenClassToGroupDetails() {
    if(this.detailTarget.open) {
      this.detailTarget.classList.remove("open")
    } else{
      this.detailTarget.classList.add("open")
    }
  }
}
