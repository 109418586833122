import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="direct-tab-calibration"
export default class extends Controller {
  static targets = ['fileTab', 'inputTab', 'file', 'input', 'calibrationInputField', 'fileField', 'activeTab']
  static values = { currentTab: Number }

  connect() {
    // this set timeout for pending calibration_device_input_targets initial
    setTimeout(() => {
      this.currentTabValue == 1 ? this.show_file_tab() : this.show_input_tab()
    },0)
  }

  show_file_tab() {
    this.currentTabValue = 1

    this.fileTarget.classList.remove("absolute", "left-[-9999px]")
    this.fileTabTarget.classList.add("active")
    this.inputTarget.classList.add("absolute", "left-[-9999px]")
    this.inputTabTarget.classList.remove("active")

    this.fileFieldTarget.disabled = false

    this.calibrationInputFieldTargets.forEach((item) => {
      if(item.slim) {
        if(item.parentElement.firstChild.nodeName == 'INPUT') {
          item.parentElement.firstChild.disabled = true
        }
      }
      item.disabled = true
    })
    this.activeTabTarget.value = 1
  }

  show_input_tab() {
    this.currentTabValue = 2

    this.fileTarget.classList.add("absolute", "left-[-9999px]")
    this.fileTabTarget.classList.remove("active")
    this.inputTarget.classList.remove("absolute", "left-[-9999px]")
    this.inputTabTarget.classList.add("active")

    this.fileFieldTarget.disabled = true
    this.calibrationInputFieldTargets.forEach((item) => {
      if(item.slim) {
        if(item.parentElement.firstChild.nodeName == 'INPUT') {
          item.parentElement.firstChild.disabled = false
        }
      }
      item.disabled = false
    })
    this.activeTabTarget.value = 2
  }
}
