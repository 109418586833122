import ApplicationController from "./application_controller";

export default class extends ApplicationController {
  static targets = [ "button", "form" ];

  change() {
    // For some reson we have to wait a moment and click an actual button
    // I think it has something to do with the date picker
    setTimeout(() => {
      this.buttonTarget.click();
    }, 200);
  }
}
