// Entry point for the build script in your package.json
import "trix";
import "@rails/actiontext";
import "./extensions/active_storage";
import "@hotwired/turbo-rails";

import { confirm } from "./extensions/turbo_confirm_override"
Turbo.setConfirmMethod(confirm)

import LocalTime from 'local-time';
LocalTime.start();

import ahoy from "ahoy.js";
import MicroModal from 'micromodal';

// Custom Elements
import { EmailTemplateHelper } from './custom_elements/email_template_helper'
customElements.define('email-template-helper', EmailTemplateHelper)

import { SearchResult } from "./custom_elements/search_result"
customElements.define('search-result', SearchResult)

import { CancelRequestProvider } from "./custom_elements/cancel_request_provider"
customElements.define('cancel-request-provider', CancelRequestProvider)

import { CancelRequestSelectedServices } from "./custom_elements/cancel_request_selected_services"
customElements.define('cancel-request-selected-services', CancelRequestSelectedServices)

import { SearchSelectedServices } from "./custom_elements/search_selected_services"
customElements.define('selected-services', SearchSelectedServices)

import { Toast } from "./custom_elements/toast"
customElements.define('toast-notification', Toast)
//////////////

ahoy.configure({cookies: false})
ahoy.trackView();
ahoy.trackClicks("a, button, input[type=submit]");
ahoy.trackSubmits("form");
ahoy.trackChanges("input, textarea, select");

// MicroModal is not a stimulus controller (which connects automatically on frame loads),
// we have to do it manually. There are two relevant events: turbo:load and turbo:frame-load
// It seems the frame-load event triggers after page loads as well, but load does not
// trigger after a frame is re-loaded.
document.addEventListener('turbo:frame-load', () => {
  MicroModal.init({
    disableFocus: true,
    onShow: (modal, el, event) => {
      event.preventDefault()
    }
  });
});
document.addEventListener('turbo:load', () => {
  MicroModal.init({
    disableFocus: true,
    onShow: (modal, el, event) => {
      event.preventDefault()
    }
  });
});

Turbo.StreamActions.remove_later = function () {
  setTimeout(() => {
    this.targetElements.forEach( e =>  { e.remove() })
  }, this.getAttribute("after"))
}

Turbo.StreamActions.scroll = function () {
  this.targetElements[0].scrollIntoView({
    behavior: 'smooth'
  })
}

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

import "./controllers"

import { Autoresizer } from "./extensions/autoresizer"
Autoresizer.start()
