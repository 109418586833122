import ApplicationController from "./application_controller";
import Litepicker from 'litepicker';
import {get} from "@rails/request.js";

// Connects to data-controller="classifiable-table-helper-date-picker"
export default class extends ApplicationController {
  static targets = ["pickerElement", "url", "model", "attribute"]
  connect() {
    this.pickerElementTarget.addEventListener('click', this.preventDefaultBehavior)
    this.pickerElementTarget.addEventListener('keyup', this.preventDefaultBehavior)
    this.pickerElementTarget.addEventListener('keydown', this.preventDefaultBehavior)
    const picker = new Litepicker({
      element: this.pickerElementTarget,
      autoRefresh: true,
      setup: (picker) => {
        picker.on("selected", (date1) => {
          let value = this.urlTarget.getAttribute("value");
          let model = this.modelTarget.getAttribute("value");
          let attribute = this.attributeTarget.getAttribute("value");
          let currentDate = new Date(date1.toLocaleString())
          let nextDate = new Date(currentDate.setDate(currentDate.getDate() + 1)).toISOString();
          let preDate = new Date(currentDate.setDate(currentDate.getDate() - 1)).toISOString();
          get(`${value}?model=${model}&attribute=${attribute}&dateFrom=${preDate}&dateTo=${nextDate}`, {
            responseKind: "turbo-stream"
          })
        });
        picker.on('hide', () => {
          picker.clearSelection()
        });
      },
    })
  }

  preventDefaultBehavior(e) {
    e.preventDefault()
  }
}
