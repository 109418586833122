import ApplicationController from "./application_controller";
import SlimSelect from "slim-select";

// Connects to data-controller="slim-select-single"
export default class extends ApplicationController {
  connect() {
    new SlimSelect({
      select: this.element
    })
  }
}
