import ApplicationController from "./application_controller";

// Connects to data-controller="tab-contents"
export default class extends ApplicationController {
  static targets = [ 'tab', 'tabContent']
  static values = {
    extraClasses: { type: Array, default: ["!border-tangerine-80"] }
  }

  connect() {
    window.addEventListener('popstate', this.reloadLocation.bind(this));
    this.onHashChangeEvent()
  }

  reloadLocation() {
    if(window.location.hash) location.reload()
  }

  onHashChangeEvent = () =>{
    let hashContent = window.location.hash?.slice(1) || this.tabTarget.dataset.tabId
    let tabTarget = document.querySelector(`[data-tab-contents-target="tab"][data-tab-id="${hashContent}"]`)

    if(tabTarget) {
      this.changeTabTarget({ target: tabTarget })
      this.changeTabContentTarget({ target: tabTarget })
    }
  }

  changeHash = (e) => {
    window.history.pushState(null, '', `#${e.target.dataset.tabId}`)
    this.onHashChangeEvent()
  }

  changeTab = (e) => {
    this.changeTabTarget(e)
    this.changeTabContentTarget(e)
  }

  changeTabTarget = (e) => {
    this.tabTargets.forEach((tab) => {
      if (tab === e.target) {
        if(!tab.classList.contains('active')) { tab.classList.add('active', ...this.extraClassesValue) }
      } else {
        if(tab.classList.contains('active')) { tab.classList.remove('active', ...this.extraClassesValue) }
      }
    })
  }

  changeTabContentTarget = (e) => {
    this.tabContentTargets.forEach((tabContent) => {
      if(tabContent.dataset.tabId === e.target.dataset.tabId) {
        if(tabContent.classList.contains('hidden')) tabContent.classList.remove('hidden')
      } else {
        if(!tabContent.classList.contains('hidden')) tabContent.classList.add('hidden')
      }
    })
  }
}

