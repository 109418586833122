import { Controller } from "@hotwired/stimulus"
import { post } from "@rails/request.js"

// Connects to data-controller="pre-fill-template"
export default class extends Controller {
  static targets = ['template']

  static  values = {
    url: String,
    preTemplate: String
  }

  fillData() {
    post(`${this.urlValue}?template=${this.templateTarget.value}`, {
      responseKind: "turbo-stream"
    });
  }

  changeTemplate() {
    this.templateTarget.value = this.preTemplateValue
  }
}
