import ApplicationController from "./application_controller";
import { meta } from "../helpers/meta"

export default class extends ApplicationController {
  static values = { title: String, message: String, lostmessage: String, nestedform: String, redirect: String, cancel: String, ok: String, customform: String }

  showConfirmation(event) {
    let buttonCancel = meta("confirm:button:cancel")
    let buttonOk = meta("confirm:button:ok");
    let customClass = "vl-confirmation";
    if(this.customformValue) customClass = this.customformValue
    if(this.cancelValue) buttonCancel = this.cancelValue
    if(this.okValue) buttonOk = this.okValue

    if (this.element.dataset.confirmed == "true") { return }
    event.preventDefault()

    swal({
      title: this.title,
      text: this.message,
      icon: 'warning',
      buttons: [buttonCancel, buttonOk],
      className: customClass
    }).then((result) => {
      if (result) {
        this.continue()
      }

    })
  }

  showConfirmationOffer(event) {
    // if (this.element.dataset.confirmed == "true") { return }
    event.preventDefault()

    swal({
      title: this.title,
      text: this.message,
      icon: 'warning',
      buttons: [meta("confirm:button:cancel"), meta("confirm:button:ok")],
      className: 'vl-confirmation'
    }).then((result) => {
      if (result) {
        this.continue()
      }

    })
  }

  showConfirmationCancel(event) {
    if (this.element.dataset.confirmed == "true") { return }
    event.preventDefault()

    swal({
      title: this.title,
      text: this.message,
      icon: 'warning',
      buttons: [meta("confirm:button:cancel"), meta("confirm:button:ok")],
      className: 'vl-confirmation'
    }).then((result) => {
      if (result) {
        this.dispatch("confirm:success")
      } else {
        this.dispatch("confirm:cancel")
      }
    })
  }

  continue() {
    if (this.element.form) {
      this.element.dataset.confirmed = "true"
      this.element.form.requestSubmit(this.element)

    } else {
      this.dispatch("confirm:success")
    }
  }

  get title() {
    if (!this.lostmessageValue) {
      return this.titleValue || meta("confirm:title")
    } else {
      let field_set = this.lostmessageValue.split(',')
      let warnning = []
      for(let i = 0; i < field_set.length; i++) {
        let change = field_set[i].split(':')
        let check = document.getElementById(change[0])
        if (check && check.value) {}
        else {
          warnning.push(`${change[1][0].toUpperCase() + change[1].substring(1)} is empty now.`)
        }
      }
      if(this.nestedformValue) {
        let check_nested_form = document.getElementsByClassName('nested-form-wrapper')
        if(check_nested_form.length == 0) {
          warnning.push(`You need create some service`)
        }
      }

      if (warnning.length == 0) {
        return this.titleValue || meta("confirm:title")
      }
      return "You must fill all data"
    }
  }

  get message() {
    if (!this.lostmessageValue) {
      return this.messageValue || meta("confirm:message")
    } else {
      let field_set = this.lostmessageValue.split(',')
      let warnning = []
      for(let i = 0; i < field_set.length; i++) {
        let change = field_set[i].split(':')
        let check = document.getElementById(change[0])
        if (check && check.value) {}
        else {
          warnning.push(`${change[1][0].toUpperCase() + change[1].substring(1)} is empty now.`)
        }
      }
      if(this.nestedformValue) {
        let check_nested_form = document.getElementsByClassName('nested-form-wrapper')
        if(check_nested_form.length == 0) {
          warnning.push(`You need create some service`)
        }
      }
      if (warnning.length == 0) {
        return this.messageValue || meta("confirm:message")
      }
      return warnning.join('\n')
    }
  }

  redirectFunc() {
    window.location = this.redirectValue
  }
}
