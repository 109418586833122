import NestedForm from "stimulus-rails-nested-form"

// Connects to data-controller="nias-nested-sample-composition-analysis"
export default class extends NestedForm {
  static targets = ['compositionAnalysis', 'cAId', 'destroy', 'analysisType', 'notes', 'analysisTypeCheckbox', 'analysisTypeNote']

  connect() {
    this.element[this.identifier] = this
  }

  add(e, index = 0) {
    e.preventDefault();
    const content = this.templateTarget.innerHTML
      .replace(/NEW_COMPOSITION_SAMPLE/g, new Date().getTime().toString() + index * 200)

    this.targetTarget.insertAdjacentHTML("beforebegin", content);
  }


  get getTargetData() {
    return {
      compositionAnalyses: this.compositionAnalysisTargets.map((item, index) => ({
        analysisType: this.analysisTypeTargets[index].value,
        notes: this.notesTargets[index].value
      })),
      analysisTypes: this.analysisTypeCheckboxTargets.map((item, index) => ({
        analysisTypeChecked: this.analysisTypeCheckboxTargets[index].checked
      }))
    }
  }

  setTargetData(e, values) {
    values.analysisTypes.map((item, index) => {
      this.analysisTypeCheckboxTargets[index].checked = item.analysisTypeChecked
      if (item.analysisTypeChecked) {
        this.analysisTypeCheckboxTargets[index].closest('.table--analysis ').classList.add('bg-green-20')
      }
    })

    values.compositionAnalyses.map((item, index) => {
      this.add(e, index)
      let targets = this.analysisTypeTargets.length - 1
      this.analysisTypeTargets[targets].value = item.analysisType
      this.notesTargets[targets].value = item.notes
    })
  }

  updateCompositionAnalysisTable(e) {
    let selectedTypes = []
    if (this.analysisTypeTargets.length > 0) {
      this.analysisTypeTargets.map((item, index) => {
        selectedTypes[item.value] = index
      })
    }

    let targets = this.analysisTypeTargets.length
    let analysisTypeName = e.target.value

    if (e.target.checked) {
      if (analysisTypeName in selectedTypes) {
        this.destroyTargets[selectedTypes[analysisTypeName]].value = false
      } else {
        this.add(e)
        this.analysisTypeTargets[targets].value = analysisTypeName
        this.analysisTypeNoteTargets[targets].value = 'analysisNote'
      }

    } else {
      if (this.cAIdTargets[selectedTypes[analysisTypeName]].value != '') {
        this.destroyTargets[selectedTypes[analysisTypeName]].value = true
      } else {
        this.compositionAnalysisTargets[selectedTypes[analysisTypeName]].remove()
      }
    }
  }

  hightColor(event) {
    let dom = event.target.closest('.table--analysis')
    if (event.target.checked) {
      dom.classList.add('bg-green-20')
      dom.querySelector('.analysis_short_description').classList.add('hidden')
    } else {
      dom.classList.remove('bg-green-20')
      dom.querySelector('.analysis_short_description').classList.remove('hidden')
    }

  }
}
