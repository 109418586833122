import ApplicationController from "./application_controller";

// Connects to data-controller="total"
// Calculates the total of input fields
// It can add the result as textContent to dst,
// and as value to an input defined by CSS selector result target (for nesting)
export default class extends ApplicationController {
  static targets = ['src', 'dst'];
  static values = {
    result: String
  };

  connect() {
    this.updateSum();
  }

  srcTargetConnected(element) {
    element.addEventListener('change', this.updateSum.bind(this));
  }

  updateSum() {
    const sum = this.srcTargets
      .map(target => parseFloat(target.value) || 0.0)
      .reduce((a, b) => a + b, 0.0);

    this.dstTargets.forEach((e) => e.textContent = sum.toFixed(2));
    const targetInput = document.getElementById(this.resultValue);
    if (targetInput) {
      targetInput.value = sum;
      targetInput.dispatchEvent(new CustomEvent('change'));
    }
  }
}
