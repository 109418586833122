import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="handler-exception"
export default class extends Controller {
  static targets = ['boxLink']

  attachLink(e) {
    this.boxLinkTargets.map(item => {
      item.classList.remove('bg-light-grey-40')
    })

    if (e.target.tagName.toLowerCase() === 'div') {
      e.target.classList.add('bg-light-grey-40')
      document.getElementById('btn-switch-account').closest('form').action = e.target.querySelector('input').value
    } else {
      e.target.closest('div').classList.add('bg-light-grey-40')
      document.getElementById('btn-switch-account').closest('form').action = e.target.closest('div').querySelector('input').value
    }
  }
}
