import ApplicationController from "./application_controller";

export default class extends ApplicationController {
  connect() {
    this.element.dataset.chosen = this.element.value
  }

  change(event) {
    event.target.dataset.chosen = event.target.value
  }
}
