import ApplicationController from './application_controller';
import {get} from "@rails/request.js";

export default class extends ApplicationController {
  static targets = [ "click" ]

  click() {
    this.clickTargets.forEach(target => target.click())
  }

  change(e) {
    let selected = e.target;
    let tender_id = document.getElementById("tender_id");
    let value
    if (tender_id) {
      value = tender_id.getAttribute('value');
    }
    if (value) {
      if(selected[selected.selectedIndex].getAttribute('value')) {
        get(` /tenders/replace_content?companyId=${selected[selected.selectedIndex].getAttribute('value')}&tender_id=${value}`, {
          responseKind: "turbo-stream"
        })
      }
    } else {
      if(selected[selected.selectedIndex].getAttribute('value')) {
        get(` /tenders/replace_content?companyId=${selected[selected.selectedIndex].getAttribute('value')}`, {
          responseKind: "turbo-stream"
        })
      }
    }

  }
}
